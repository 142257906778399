import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkoutPage } from '../../routing/PageRoutes';
import DialogSpecial from './DialogSpecial/DialogSpecial';
import StyledButton from './StyledTextButton';
import ApiClient from '../../Api/ApiClient';
import { setOpenDialog } from '../../__reducer/ServiceConflictReducer';

function removeLocalItems(query) {
  for (let i = 0; i < window.localStorage.length; i++) {
    if (window.localStorage.key(i).indexOf(query) != -1) {
      window.localStorage.removeItem(window.localStorage.key(i));
    }
  }
}

const CheckoutOrderButton = ({ closeBasket }) => {
  const history = useHistory();
  const allCodes = useSelector((state) => state.basket.allServiceCodes);
  const dispatch = useDispatch();
  const [currentPromotion, setCurrentPromotion] = useState(null);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = async () => {
    const check = await ApiClient.CheckPromotion()
      .then((res) => {
        if (res?.data?.hasPromotion === false) {
          removeLocalItems('specialService');
        }
        return res.data;
      })
      .catch((e) => {
        dispatch(
          setOpenDialog({
            message: e.message,
          })
        );
        return null;
      });

    if (check) {
      const { hasPromotion, promotion } = check;
      if (hasPromotion) {
        const isServiceAdded = allCodes.indexOf(promotion.serviceCode) !== -1;

        const hasAnswered = Boolean(
          window.localStorage.getItem(`specialService${promotion.id}`)
        );
        if (!isServiceAdded && !hasAnswered) {
          setCurrentPromotion(promotion);
          return handleOpen();
        }
      }
    }
    history.push(checkoutPage);
    closeBasket();
  };

  return (
    <>
      <StyledButton
        fullWidth
        disableRipple
        onClick={handleClick}
        variant="contained"
      >
        Оформить заказ
      </StyledButton>
      <DialogSpecial
        open={open}
        promotion={currentPromotion}
        onClose={handleClose}
        closeBasket={closeBasket}
      />
    </>
  );
};

CheckoutOrderButton.propTypes = {
  closeBasket: PropTypes.func.isRequired,
};

export default CheckoutOrderButton;
