import genderHelper from '../components/_helper/genderHelper';
import {
  ADD_USER_GENDER,
  ADD_USER_CITY,
  ADD_USER_DATA,
} from '../__actions/action_const/user_const';

const initState = {
  gender: genderHelper.noSet,
  City: { id: 1, cityName: 'Минск' },
  dateOfBirth: null,
  email: '',
  phoneNumber: '',
  paymentChannel: '',
  laboratory: null,
  firstCheckbox: false,
  secondCheckbox: false,
  theirdCheckbox: false,
  phone: '',
  firstName: '',
  lastName: '',
  middleName: '',
};

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case ADD_USER_GENDER:
      return {
        ...state,
        gender: action.payload,
      };
    case ADD_USER_CITY:
      return {
        ...state,
        City: action.payload,
      };
    case ADD_USER_DATA: {
      const data = action.payload;
      return {
        ...state,
        dateOfBirth: data.dateOfBirth,
        email: data.email,
        phone: data.phone,
        paymentChannel: data.paymentChannel,
        laboratory: data.laboratory,
        firstCheckbox: data.firstCheckbox,
        secondCheckbox: data.secondCheckbox,
        theirdCheckbox: data.theirdCheckbox,
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
      };
    }
    default:
      return state;
  }
}
