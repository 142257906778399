import React, { useState } from 'react';
import { IconButton, Popover } from '@material-ui/core';
import InfoIcon from '../img/info.svg';
import './ClickPopover.scss';
import PropTypes from 'prop-types';
import CommentIcon from '../../services_page/services_table/img/comment.svg';
import AttentionIcon from '../../services_page/services_table/img/attention.svg';
import DescriptionIcon from '../../services_page/services_table/img/info.svg';

const ClickPopover = ({ service }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function DescPattern(text) {
    return (
      <div className="click-popover__item">
        <span className="click-popover__icon">
          <img
            src={DescriptionIcon}
            alt=""
            width="24px"
            height="24px"
            loading="lazy"
          />
        </span>
        <span className="click-popover__text click-popover__text--description">
          <p>{text}</p>
        </span>
      </div>
    );
  }

  function Description() {
    const { description, results } = service;

    if (description !== null && description.length > 0) {
      if (results !== null && results.length > 0) {
        return DescPattern(`${description} ${results}`);
      }
      return DescPattern(description);
    }
    if (results !== null && results.length > 0) {
      return DescPattern(results);
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton
        className="basket__popover-btn"
        onClick={handleClick}
        size="small"
      >
        <img width="24px" height="24px" src={InfoIcon} alt="" loading="lazy" />
      </IconButton>
      <Popover
        className="click-popover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: { maxWidth: 600 }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {(service.remark ||
          service.comment ||
          service.description ||
          service.results) && (
          <div className="click-popover__wrapper">
            {service.comment !== null && service.comment.length > 0 && (
              <div className="click-popover__item">
                <span className="click-popover__icon">
                  <img
                    src={AttentionIcon}
                    alt=""
                    width="24px"
                    height="24px"
                    loading="lazy"
                  />
                </span>
                <span className="click-popover__text click-popover__text--comment">
                  <p dangerouslySetInnerHTML={{ __html: service.comment }}></p>
                </span>
              </div>
            )}
            {service.remark !== null && service.remark.length > 0 && (
              <div className="click-popover__item">
                <span className="click-popover__icon">
                  <img
                    src={CommentIcon}
                    alt=""
                    width="24px"
                    height="24px"
                    loading="lazy"
                  />
                </span>
                <span className="click-popover__text click-popover__text--remark">
                  <p dangerouslySetInnerHTML={{ __html: service.remark }}></p>
                </span>
              </div>
            )}
            {Description()}
          </div>
        )}
      </Popover>
    </>
  );
};

ClickPopover.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ClickPopover;
