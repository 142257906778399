import React from 'react';
import { useSelector } from 'react-redux';
import { fetchStatus } from '../_helper/fetchStatus';
import LeavePageBlocker from '../_helper/LeavePageBlocker';

const ServiceAddingBlock = () => {
  const isLoading = useSelector(
    (state) => state.addingService.status === fetchStatus.PENDING
  );
  return <LeavePageBlocker when={isLoading} />;
};

export default ServiceAddingBlock;
