import React, { useState } from 'react';
import { useMediaQuery, Box, makeStyles, ThemeProvider } from '@material-ui/core';
import HeaderTop from './HeaderTop';
import HeaderMid from './HeaderMid';
import HeaderBot from './HeaderBot.jsx';
import theme from '../_helper/MuiTheme';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '1420px',
    padding: '0px 30px',
    margin: '0 auto',
    boxSizing: 'border-box',
  },
  whiteBackground: {
    backgroundColor: '#ffffff',
  },
  midBackground: {
    backgroundColor: '#EFF0F4',
  }
}));

const Header = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <ThemeProvider theme={theme}>
      <header className={classes.header}>
        <Box className={classes.whiteBackground}>
          <Box className={classes.container}>
            <HeaderTop />
          </Box>
        </Box>
        <Box className={classes.midBackground}>
          <Box className={classes.container}>
            <HeaderMid />
          </Box>
        </Box>
        {isMobile && (
          <Box className={classes.whiteBackground}>
            <Box className={classes.container}>
              <HeaderBot />
            </Box>
          </Box>
        )}
      </header>
    </ThemeProvider>
  );
};

export default Header;