import { Box, Link, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: 'Golos',
    fontSize: '10px',
    lineHeight: '12px',
    color: '#FFFFFF !important',
    marginBottom: 0,
    textAlign: 'center',
  },
}));

const LinkItem = ({ icon: Icon, label, href }) => {
  const classes = useStyles();

  return (
    <Box
      px={1}
      component={Link}
      href={href}
      display="flex"
      flexDirection="column"
      alignItems="center"
      color="white"
      gridGap={4}
      maxWidth={100}
    >
      <Box>
        <Icon width="24px" heigth="24px" />
      </Box>
      <p className={classes.label}>{label}</p>
    </Box>
  );
};

export default LinkItem;
