import React from 'react';
import Services from '../components/services_page/Services';
import { manualPage } from './PageRoutes';

const PayInERIP = React.lazy(() => import('../components/success_payment_page/Payment/PayInERIP'));
const PayOnSite = React.lazy(() => import('../components/success_payment_page/Payment/PayOnSite'));
const PayInLaboratory = React.lazy(() => import('../components/success_payment_page/Payment/PayInlaboratory'));
const CheckoutForm = React.lazy(() => import('../components/checkout_order_page/CheckoutForm'));
const ConfirmData = React.lazy(() => import('../components/confirm_data_page/ConfirmData'));
const PaymentError = React.lazy(() => import('../components/success_payment_page/PaymentError'));
const Results = React.lazy(() => import('../components/Results/Results'));
const Error404 = React.lazy(() => import('../components/Error/Error404'));
const ServiceExtension = React.lazy(() => import('../components/ServiceExtension/ServiceExtension'));
const LoginForm = React.lazy(() => import('../components/admin/LoginForm'));

export default [
  {
    path: '/',
    name: 'Services',
    Component: Services,
  },
  {
    path: '/services',
    name: 'Services',
    Component: Services,
  },
  {
    path: '/services/checkout',
    name: 'Checkout_order',
    Component: CheckoutForm,
  },
  {
    path: '/services/checkout/verification',
    name: 'Confirm_data',
    Component: ConfirmData,
  },
  {
    path: '/payment-success-card=:barcode',
    name: 'Success_payment',
    Component: PayOnSite,
  },
  {
    path: '/payment-success-erip=:barcode',
    name: 'Success_payment',
    Component: PayInERIP,
  },
  {
    path: '/payment-success-cash=:barcode',
    name: 'Success_payment',
    Component: PayInLaboratory,
  },
  {
    path: '/payment-error',
    name: 'Success_payment',
    Component: PaymentError,
  },
  {
    path: '/search=:code',
    name: 'Services',
    Component: Services,
  },
  {
    path: '/medicalServiceGroupId=:id',
    name: 'Services',
    Component: Services,
  },
  {
    path: '/clientServiceGroupId=:id',
    name: 'Services',
    Component: Services,
  },
  {
    path: '/login',
    name: 'Login',
    Component: LoginForm,
  },
  {
    path: '/results',
    name: 'Results',
    Component: Results,
  },
  {
    path: '/error404',
    name: 'Eror404',
    Component: Error404,
  },
  {
    path: manualPage,
    name: 'ServiceExtension',
    Component: ServiceExtension,
  },
];
