import React, { useEffect, useState } from 'react';
import { Portal } from '@material-ui/core';
import PropTypes from 'prop-types';
import './Hint.scss';
import arrow from './img/arrow.svg';

const Hint = ({ enableBtn }) => {
  const [hint, setHint] = useState(true);
  useEffect(() => {
    if (hint) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      document.body.classList.add('hint-blocked');
      document.getElementById('SibebarBtn').classList.add('sidebarbtn--index');
    }
  }, []);

  const handleDisableHint = () => {
    window.localStorage.setItem('disable-hint', true);
    document.body.classList.remove('hint-blocked');
    document.getElementById('SibebarBtn').classList.remove('sidebarbtn--index');
    setHint(false);
    enableBtn();
  };

  return (
    (hint && (
      <div className="hint">
        <div className="hint__wrap">
          <div className="hint__transparrent" />
          <Portal>
            <div className="hint__bgc" />
          </Portal>
          <img src={arrow} alt="" className="hint__arrow" loading="lazy" />
          <p className="hint__label">
            Необходимый анализ можно выбрать из каталога
          </p>
          <button
            name="Hint confirm"
            type="button"
            className="hint__submit btn-outlined"
            onClick={handleDisableHint}
          >
            Понятно
          </button>
        </div>
      </div>
    ))
    || null
  );
};

Hint.propTypes = {
  enableBtn: PropTypes.func.isRequired,
};

export default Hint;
