import * as axios from 'axios';
import ApiClient from './ApiClient';

export default axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    // console.log(error)
    const originalReq = error.config;
    if (originalReq.url === process.env.REACT_APP_ANALYTICS) {
      throw new Error(error);
    }
    if (error.response.status === 401) {
      const token = await ApiClient.Token().then((res) => res.data.token);
      window.localStorage.setItem('Token', token);
      originalReq.headers.Authorization = `Bearer ${token}`;
      // console.log(originalReq);
      return axios(originalReq);
    }
    return error;
  }
);
