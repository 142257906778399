import React from 'react';
import FooterContent from './FooterContent';
import { Hidden, useMediaQuery } from '@material-ui/core';
import MobileBottomNavigation from '../MobileBottomNavigation/MobileBottomNavigation';
import theme from '../_helper/MuiTheme';

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <FooterContent />
      </footer>
      <Hidden smUp>
        <MobileBottomNavigation />
      </Hidden>
    </>
  );
};

export default Footer;
