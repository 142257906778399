import { ADMIN_LOGIN, ADMIN_LOGOUT } from '../__actions/admin_actions';

const initState = {
  role: null,
};

export default function adminReducer(state = initState, action) {
  switch (action.type) {
    case ADMIN_LOGIN: {
      return {
        ...state,
        role: action.payload,
      };
    }
    case ADMIN_LOGOUT: {
      return {
        ...state,
        role: null,
      };
    }
    default: {
      return state;
    }
  }
}
