import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

const ServiceBackdrop = () => {
  const open = useSelector((state) => state.loading.value);
  const isBasketOpen = useSelector((state) => state.basket.isOpen);
  return (
    <Backdrop open={open && !isBasketOpen} style={{ zIndex: 1300 }}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default ServiceBackdrop;
