import * as axios from 'axios';
import genderHelper from '../components/_helper/genderHelper';
import { contractId, userData } from './config';

class ApiClient {
  static Headers = async (options) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (options.authorization) {
      let tokenData = window.localStorage.getItem('Token');

      if (!tokenData) {
        tokenData = await ApiClient.Token().then(
          (res) => res?.data?.token || null
        );
        window.localStorage.setItem('Token', tokenData);
      }

      headers.Authorization = `Bearer ${tokenData}`;
    }

    if (options.Recaptcha) {
      headers.Recaptcha = options.Recaptcha;
    }

    return headers;
  };

  static post = async (url, data = {}, options = { authorization: true }) => {
    const headers = await ApiClient.Headers(options);

    try {
      const response = await axios.post(url, data, {
        headers,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static get = async (url, options = { authorization: true }) => {
    const headers = await ApiClient.Headers(options);
    try {
      const response = await axios.get(url, {
        headers,
      });
      // console.log(response);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static Token = async () => {
    const url = process.env.REACT_APP_GET_TOKEN;
    return ApiClient.post(url, userData, { authorization: false });
  };

  static Services = async (
    page,
    pageSize,
    gender,
    clientServiceGroupId = null,
    medicalServiceGroupId = null
  ) => {
    const params = new URLSearchParams();

    params.append('contractId', contractId);
    params.append('page', page);
    params.append('pageSize', pageSize);
    if (gender !== 'U') {
      params.append('genderId', genderHelper.all.get(gender));
    }
    if (medicalServiceGroupId !== null) {
      params.append('medicalServiceGroupId', medicalServiceGroupId);
    }
    if (clientServiceGroupId !== null) {
      params.append('clientServiceGroupId', clientServiceGroupId);
    }

    const url = `${
      process.env.REACT_APP_BASE_URL
    }/preordering/v1.0/Service?${params.toString()}`;

    return ApiClient.get(url);
  };

  static MedicalServiceGroup = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/preordering/v1.0/MedicalServiceGroup`;

    return ApiClient.get(url);
  };

  static ClientServiceGroup = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/preordering/v1.0/ClientServiceGroup`;

    return ApiClient.get(url);
  };

  static Calculator = async (gender, serviceCodes) => {
    const url = `${process.env.REACT_APP_CALCULATOR}?gender=${gender}&serviceCodes=${serviceCodes}&contractId=${contractId}`;

    return ApiClient.get(url);
  };

  static PriceWithDiscount = async (gender, serviceCodes) => {
    const url = `${process.env.REACT_APP_BASE_URL}/preordering/v1.0/Calculator/PriceWithDiscount?gender=${gender}&serviceCodes=${serviceCodes}&contractId=${contractId}`;

    return ApiClient.get(url);
  };

  static CodesCalculator = async (gender, serviceCodes) => {
    const url = `${process.env.REACT_APP_CODES_CALCULATOR}?gender=${gender}&serviceCodes=${serviceCodes}`;

    return ApiClient.get(url);
  };

  static Search = async (term, gender) => {
    const params = new URLSearchParams();
    params.append('contractId', contractId);
    params.append('term', term);
    if (gender !== 'U') {
      params.append('genderId', genderHelper.all.get(gender));
    }

    const url = `${
      process.env.REACT_APP_BASE_URL
    }/preordering/v1.0/Service/search?${params.toString()}`;

    return ApiClient.get(url);
  };

  static MedicalCentersByService = async (serviceCodes) => {
    const url = `${process.env.REACT_APP_MEDICAL_CENTER_BY_SERVICE}?code=${serviceCodes}`;

    return ApiClient.get(url);
  };

  /**
   * Проверяет на наличее конфликтов
   * @async
   * @param {String[]} codes коды всег услуг
   * @returns {Promise<{
   * isSuccess: boolean,
   * yes: string,
   * no: string,
   * statusMessage: string
   * }>} Список конфликтующих услуг если конфликт
   */
  static CheckServices = async (codes) => {
    const url = `${process.env.REACT_APP_CHECK_SERVICES}?code=${codes}`;

    return ApiClient.get(url);
  };

  static GetMedicalCenterBiomaterialSamplingSchedule = async (
    MedicalCenterCode,
    ServiceCode
  ) => {
    const url = `${process.env.REACT_APP_GET_MEDICAL_CENTERS_BIOMATERIAL_SAMPLING_SCHEDULE}?code=${MedicalCenterCode}&serviceCode=${ServiceCode}`;

    return ApiClient.get(url);
  };

  static PreOrder = async (data, token) => {
    const url = process.env.REACT_APP_POST_PRE_ORDER;

    return ApiClient.post(url, data, { authorization: true, Recaptcha: token });
  };

  static GetPrice = async (codes) => {
    const url = `${process.env.REACT_APP_BASE_URL}/preordering/v1.0/service/prices?contractId=${contractId}&code=${codes}`;
    return ApiClient.get(url);
  };

  /**
   * Возвращает список услуг со скидкой 5%
   * @param {string[]} codes массив кодов услуг
   */
  static GetPriceV2 = async (codes) => {
    const url = `${process.env.REACT_APP_BASE_URL}/preordering/v2.0/service/prices?contractId=${contractId}&code=${codes}`;
    return ApiClient.get(url);
  };

  static ServiceExtension = async (name) => {
    const url = `${process.env.REACT_APP_BASE_URL}/preordering/v1.0/ServiceExtension/details?name=${name}`;

    return ApiClient.get(url);
  };

  static CheckDiscount = async (data, Recaptcha) => {
    const url = `${process.env.REACT_APP_BASE_URL}/preordering/v1.0/discount/check`;

    return ApiClient.post(url, data, {
      authorization: true,
      Recaptcha,
    });
  };

  // Promotion

   static CheckPromotion = async () => {
     const url = `${process.env.REACT_APP_BASE_URL}/preordering/v1.0/Promotion/Check?contractId=${contractId}`;
     return ApiClient.get(url);
   };

  static Analytics = async (username, password, captcha) => {
    const url = process.env.REACT_APP_ANALYTICS;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${window.btoa(`${username}:${password}`)}`,
          Recaptcha: captcha,
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };
}

export default ApiClient;
