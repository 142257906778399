export const CLIENT_GROUP = 'client';
export const MEDICAL_GROUP = 'medical';
export const MEDICAL_SERVICE_GROUP = 'medicalServiceGroupId';
export const CLIENT_SERVICE_GROUP = 'clientServiceGroupId';

export const WEEK_ABBR = {
  Понедельник: 'ПН',
  Вторник: 'ВТ',
  Среда: 'СР',
  Четверг: 'ЧТ',
  Пятница: 'ПТ',
  Суббота: 'СБ',
  Воскресенье: 'ВС',
};

export const CityByDefault = 'Минск';

export const SPECIAL_SERVICE = 'special-service';
