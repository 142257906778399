import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AddServiceGroup,
  UnableRedirect,
} from '../../../../__actions/service_actions';
import './service-tabs.css';
import {
  CLIENT_GROUP,
  MEDICAL_GROUP,
  MEDICAL_SERVICE_GROUP,
} from '../../../_helper/app-const';

class ServiceTabs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { match, AddServiceGroupAction } = this.props;

    if (match?.params?.id) {
      if (match.url.indexOf(MEDICAL_SERVICE_GROUP) !== -1) {
        AddServiceGroupAction(MEDICAL_GROUP);
      } else {
        AddServiceGroupAction(CLIENT_GROUP);
      }
    } else {
      AddServiceGroupAction(CLIENT_GROUP);
    }
  }

  handleClick(e) {
    const {
      clearSearch, AddServiceGroupAction, UnableRedirectAction,
      isRedirect,
    } = this.props;

    if (isRedirect) {
      UnableRedirectAction(false);
    }

    AddServiceGroupAction(e.target.value);
    clearSearch();
  }

  render() {
    const { serviceGroup } = this.props;
    return (
      <div className="tabs-overlay">
        <div className="tabs">
          <button
            type="button"
            className={clsx('btn tabs__btn', {
              active: serviceGroup === MEDICAL_GROUP,
            })}
            value={MEDICAL_GROUP}
            onClick={this.handleClick}
          >
            Для врачей
          </button>
          <button
            type="button"
            className={clsx('btn tabs__btn', {
              active: serviceGroup === CLIENT_GROUP,
            })}
            value={CLIENT_GROUP}
            onClick={this.handleClick}
          >
            Для клиентов
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  serviceGroup: state.service.serviceGroup,
  isRedirect: state.service.redirect,
});

const mapDispatchToProps = (dispatch) => ({
  AddServiceGroupAction: (type) => dispatch(AddServiceGroup(type)),
  UnableRedirectAction: (bool) => dispatch(UnableRedirect(bool)),
});

ServiceTabs.propTypes = {
  isRedirect: PropTypes.bool.isRequired,
  serviceGroup: PropTypes.string.isRequired,
  AddServiceGroupAction: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  UnableRedirectAction: PropTypes.func.isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTabs);
