import React from 'react';
import './spinner.css';

const Spinner = () => (
  <div className="d-flex justify-content-center py-3 py-md-5">
    <div className="loader-four">
      <div className="loader-four__preloader" />
    </div>
  </div>
);

export default Spinner;
