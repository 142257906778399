const errorHelper = {
  required: 'Это поле обязательно!',
  email: 'Формат Email не верный',
  phone: 'Формат номера телефона +375-XX-XXX-XX-XX',
  date: 'Формат ДД/ММ/ГГГГ',
  tryRequestLater: 'Повторите запрос позже',
  sorryOccuredError: 'Произошла ошибка! Повторите запрос позже',
};

export default errorHelper;
