import React from 'react';
import './basket.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Drawer, IconButton } from '@material-ui/core';
import BasketImg from './img/basket.svg';
import CloseIcon from './img/close.svg';

import BasketFull from './BasketFull/BasketFull';
import BasketEmpty from './BasketEmpty/BasketEmpty';
import { changeIsOpenBasket } from '../../__reducer/BasketReducer';

const Basket = ({ hadServices, isOpen, dispatch }) => {
  const handleCloseBasket = () => {
    dispatch(changeIsOpenBasket());
  };

  const services = hadServices > 0 ? (
    <BasketFull onCancel={handleCloseBasket} />
  ) : (
    <BasketEmpty />
  );

  return (
    <Drawer
      id="basket"
      anchor="right"
      open={isOpen}
      onClose={handleCloseBasket}
      SlideProps={{
        timeout: { enter: 500, exit: 300 },
        mountOnEnter: true,
        unmountOnExit: true,
      }}
    >
      <div className="basket">
        <div className="d-flex align-items-center py-4 px-3 px-md-4">
          <img src={BasketImg} alt="" className="mr-3" />
          <span className="basket__header-text">Моя корзина</span>
          <IconButton title="Закрыть" onClick={handleCloseBasket} className="ml-auto">
            <img src={CloseIcon} alt="" />
          </IconButton>
        </div>
        {services}
      </div>
    </Drawer>
  );
};

Basket.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hadServices: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isOpen: state.basket.isOpen,
  hadServices: state.basket.addedServices.length,
});

export default connect(mapStateToProps)(Basket);
