import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import './sidebar.css';
import { connect } from 'react-redux';
import clsx from 'clsx';
import ServiceGroupSubItem from './ServiceGroupSubItem';
import { ChangeIsOpenSidebar } from '../../__actions/service_actions';
import { ReactComponent as Triang } from './img/triang.svg';
import serviceGrpoupStyles from './serviceGroupStyles';

const ServiceGroupItem = ({
  serviceGroupItem,
  changeServiceGroup,
  selectedServiceGroupId,
  dispatch,
}) => {
  const classes = serviceGrpoupStyles();

  if (serviceGroupItem?.children?.length) {
    return (
      <div className="sidebar__accordion">
        <Accordion
          square
          classes={{ root: classes.accordion }}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            classes={{ root: classes.title }}
            expandIcon={<Triang />}
          >
            {serviceGroupItem.name}
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {serviceGroupItem.children.map((item, index) => (item?.children ? (
                <ServiceGroupSubItem
                    // eslint-disable-next-line react/no-array-index-key
                  key={`${item.name}_${index}`}
                  serviceGroupItem={item}
                  changeServiceGroup={changeServiceGroup}
                />
              ) : (
                <Button
                  classes={{ root: classes.button }}
                  key={item.id}
                  className={clsx('accordion-item', {
                    selected_item: item.id === selectedServiceGroupId,
                  })}
                  onClick={() => {
                    dispatch(ChangeIsOpenSidebar());
                    changeServiceGroup(item, serviceGroupItem);
                  }}
                >
                  {item.name}
                </Button>
              )))}
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
  return (
    <div className="sidebar__accordion">
      <Button
        classes={{ root: classes.button }}
        className={clsx('sidebar__button', {
          selected_item: serviceGroupItem.id === selectedServiceGroupId,
        })}
        onClick={() => {
          dispatch(ChangeIsOpenSidebar());
          changeServiceGroup(serviceGroupItem);
        }}
      >
        {serviceGroupItem.name}
      </Button>
    </div>
  );
};

ServiceGroupItem.propTypes = {
  serviceGroupItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string,
    name: PropTypes.string,
    parrentGroupId: PropTypes.number,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string,
        name: PropTypes.string,
        parrentGroupId: PropTypes.number,
      })
    ),
  }).isRequired,
  selectedServiceGroupId: PropTypes.number,
  changeServiceGroup: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

ServiceGroupItem.defaultProps = {
  selectedServiceGroupId: null,
};

const mapState = (state) => ({
  selectedServiceGroupId: state.service.medicalServiceGroupId,
});

export default connect(mapState)(ServiceGroupItem);
