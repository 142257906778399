import React, { useState, useRef } from 'react';
import { ReactComponent as ArrowIcon } from './img/newArrow.svg';
import Dropdown from './Dropdown';

const MenuItemsHeaderBottom = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  const [multiColumn] = useState(false);

  let ref = useRef();

  const customSort = (a, b) => {
    const nameA = a.linkName.toLowerCase();
    const nameB = b.linkName.toLowerCase();
    
    if (nameA.includes("минск") && !nameB.includes("минск")) {
      return -1;
    } else if (!nameA.includes("минск") && nameB.includes("минск")) {
      return 1;
    } else {
      return nameA.localeCompare(nameB);
    }
  };

  const sortedLinks = items.links ? [...items.links].sort(customSort) : [];

  return (
    <li className="headerBottom" ref={ref}>
      {items.link && sortedLinks.length > 0 ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {window.innerWidth < 960 && depthLevel === 0 ? (
              items.title
            ) : (
              <a href={items.link} target="_blank" rel="noopener noreferrer">
                {items.title}
              </a>
            )}

            {depthLevel > 0 && window.innerWidth < 960 ? null : depthLevel > 0 && window.innerWidth > 960 ? (
              <span>&raquo;</span>
            ) : (
              <ArrowIcon className="arrow" />
            )}
          </button>
          <Dropdown depthLevel={depthLevel} submenus={sortedLinks} dropdown={dropdown} multiColumn={multiColumn} />
        </>
      ) : !items.link && sortedLinks.length > 0 ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.title} {depthLevel > 0 ? <span>&raquo;</span> : <ArrowIcon className="arrow" />}
          </button>
          <Dropdown depthLevel={depthLevel} submenus={sortedLinks} dropdown={dropdown} multiColumn={multiColumn} />
        </>
      ) : (
        <a href={items.link} target="_blank" rel="noopener noreferrer">
          {items.title}
        </a>
      )}
    </li>
  );
};

export default MenuItemsHeaderBottom;
