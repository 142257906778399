import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StyledButton from '../StyledTextButton';
import { ReactComponent as DeleteIcon } from '../../_icons/delete2.svg';
import { ClearBasket } from '../../../__reducer/BasketReducer';
import { fetchStatus } from '../../_helper/fetchStatus';

const ClearBasketButton = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.basket.status);
  const loading = status === fetchStatus.PENDING;

  const handleClearBasket = () => {
    dispatch(ClearBasket());
  };

  return (
    <div className="d-flex justify-content-end px-2 px-md-3 mb-3">
      <StyledButton
        disabled={loading}
        onClick={handleClearBasket}
        fullWidth
        variant="text"
        startIcon={<DeleteIcon />}
      >
        Очистить корзину
      </StyledButton>
    </div>
  );
};

export default ClearBasketButton;
