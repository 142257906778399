export const baseUrl = 'https://helix.by';
export const baseUrlHelix = 'https://helix.by';
export const resultsUrl = 'https://results.helix.by';
export const preorderingUrl = 'https://preordering.helix.by/';
export const resultsLoginUrl = `${resultsUrl}/login`;
export const preparationRules = `${baseUrlHelix}/preparation/`;
export const clientPolicy = `${baseUrlHelix}/opd/politika-v-otnoshenii-obrabotki-personalnyh-dannyh-klientov/`;
export const publicContract = `${baseUrlHelix}/patients/publichnyj-dogovor/`;

export const footerLinks = {
  ServicesClients: [
    {
      name: 'Адреса и время работы',
      link: `${baseUrlHelix}/centers-addresses/`,
    },
    {
      name: 'Сдать анализы',
      link: `${preorderingUrl}`,
    },
    {
      name: 'Выезд на дом и в офис',
      link: `${baseUrlHelix}/hometest/`,
    },
    {
      name: 'Скидки и акции',
      link: `${baseUrlHelix}/discounts/`,
    },
    {
      name: 'Прием врача-терапевта',
      link: `${baseUrlHelix}/novosti/1129-konsultatsiya-terapevta/`,
    },
  ],
  ReferenceInfo: [
    {
      name: 'Подготовка к анализам',
      link: `${baseUrlHelix}/preparation/`,
    },
    {
      name: 'Как получить результат',
      link: `${baseUrlHelix}/poluchenie-rezultatov-analizov/`,
    },
    {
      name: 'Способы оплаты',
      link: `${baseUrlHelix}/patients/oplata/`,
    },
    {
      name: 'О Личном кабинете',
      link: `${baseUrlHelix}/novosti/lichnyj-kabinet-dlya-vsej-semi/`,
    },
  ],
  vk: {
    name: 'Официальная страница Хеликс в ВК',
    link: 'https://vk.com/club36672803',
  },
  inst: {
    name: 'Инстаграм Хеликс',
    link: 'https://www.instagram.com/helix_by/',
  },
  facebook: {
    name: 'Официальная страница Хеликс у facebook',
    link: 'https://www.facebook.com/HelixBelarus/',
  },
  youtube: {
    name: 'Канал "Медицинская лаборатория Хеликс"',
    link: 'https://www.youtube.com/channel/UCZKiwWjnP0gzCtdVicWPxig',
  },
  tiktok: {
    name: 'Тикток Helix_belarus',
    link: 'https://www.tiktok.com/@helix_by',
  },
  publicContract: {
    name: 'Публичный договор',
    link: `${baseUrlHelix}/publichnyj-dogovor-13-04-23/`,
  },
  personalDataProcessing: {
    name: 'Обработка персональных данных',
    link: `${baseUrlHelix}/opd/`,
  },
  customerService: {
    name: 'Порядок обслуживания клиентов',
    link: `${baseUrlHelix}/patients/poryadok-obsluzhivaniya-klientov/`,
  },
  AboutUs: [
    {
      name: 'Лицензии и сертификаты',
      link: `${baseUrlHelix}/about/licenses-and-certificates/`,
    },
    {
      name: 'Видеоэкскурсии',
      link: `${baseUrlHelix}/video-ekskursiya/`,
    },
    {
      name: 'Работа у нас',
      link: `https://jobs.helix.by/`,
    },
    {
      name: 'Контакты',
      link: `${baseUrlHelix}/about/contacts/`,
    },
  ],
  results: {
    name: 'Получить результаты',
    link: `${resultsUrl}/login/code`,
  },
  discounts: {
    name: 'Скидки и акции',
    link: `${baseUrlHelix}/discounts/`,
  },
  hometest: {
    name: 'Анализы на дому ',
    link: `${baseUrlHelix}/hometest/`,
  },
};

export const headerLinks = [
  {
    title: 'КЛИЕНТАМ',
    link: `${baseUrlHelix}/patients/`,
    links: null,
  },

  {
    title: 'ВРАЧАМ',
    inRow: true,
    link: `${baseUrlHelix}/vracham/`,
    links: null,
  },

  {
    title: 'ПАРТНЕРАМ',
    link: `${baseUrlHelix}/medicine/`,
    links: null,
  },

  {
    title: 'КОРПОРАТИВНЫМ КЛИЕНТАМ',
    link: `${baseUrlHelix}/patients/korporativnym-klientam/`,
    links: null,
  },

  {
    title: 'О КОМПАНИИ',
    inRow: true,
    links: [
      {
        linkName: 'Лицензии и сертификаты',
        link: `${baseUrlHelix}/about/licenses-and-certificates/`,
      },
      {
        linkName: 'Видеоэскурсии',
        link: `${baseUrlHelix}/video-ekskursiya/`,
      },
      {
        linkName: 'Работа у нас',
        link: `https://jobs.helix.by/`,
      },
      {
        linkName: 'Контакты',
        link: `${baseUrlHelix}/about/contacts/`,
      },
    ],
  },
];

export const menuLinks = [
  {
    title: 'Анализы',
    //inRow: true,
    link: `${preorderingUrl}`,
    links: null,
    // links: [
    //   {
    //     linkName: 'Комплексные исследования',
    //     link: `${baseUrl}`,
    //   },
    //   {
    //     linkName: 'Общие анализы',
    //     link: `${baseUrl}`,
    //   },
    //   {
    //    linkName: 'Системы гемостаза',
    //     link: `${baseUrl}`,
    //   },
    //   {
    //     linkName: 'Биохимия',
    //      link: `${baseUrl}`,
    //    },
    //    {
    //     linkName: 'Гормональная панель',
    //     link: `${baseUrl}/video-ekskursiya/`,
    //   },
    //   {
    //     linkName: 'Онкологическая панель',
    //     link: `${baseUrl}/vracham/uslugi/`,
    //   },
    //   {
    //    linkName: 'Инфекционная панель',
    //     link: `${baseUrl}/vracham/ekskursii/`,
    //   },
    //   {
    //     linkName: 'Аллергология',
    //      link: `${baseUrl}/vracham/ekskursii/`,
    //    },
    //    {
    //     linkName: 'Аутоимунные заболевания',
    //     link: `${baseUrl}/video-ekskursiya/`,
    //   },
    //   {
    //     linkName: 'Иммунология',
    //     link: `${baseUrl}/vracham/uslugi/`,
    //   },
    //   {
    //    linkName: 'Определение специфических маркеров методом ПЦР',
    //     link: `${baseUrl}/vracham/ekskursii/`,
    //   },
    //   {
    //     linkName: 'Микробиология',
    //      link: `${baseUrl}/vracham/ekskursii/`,
    //    },
    //    {
    //     linkName: 'Токсико-химические исследования',
    //     link: `${baseUrl}/video-ekskursiya/`,
    //   },
    //   {
    //     linkName: 'Диагностика бесплодия',
    //     link: `${baseUrl}/vracham/uslugi/`,
    //   },
    //   {
    //    linkName: 'Анализ эакулята',
    //     link: `${baseUrl}/vracham/ekskursii/`,
    //   },
    // ],
  },
  {
    title: 'Скидки и акции',
    link: `${baseUrlHelix}/discounts/`,
    links: null,
  },

  {
    title: 'Где сдать',
    link: `${baseUrlHelix}/centers-addresses/`,
    links: null,
  },
  {
    title: 'Подготовка к анализам',
    link: `${baseUrlHelix}/preparation/`,
    links: null,
  },
  {
    title: 'Услуги',
    inRow: true,
    links: [
      {
        linkName: 'Прием врача-терапевта',
        link: `${baseUrlHelix}/novosti/1129-konsultatsiya-terapevta/`,
      },
      {
        linkName: 'Взятие биоматериала',
        link: `${baseUrlHelix}/novosti/1182-vrach-ginekolog/`,
      },
      {
        linkName: 'Полезные статьи',
        link: `${baseUrlHelix}/diagnostika/`,
      },
    ],
  },
  {
    title: 'Клиентам',
    inRow: true,
    links: [
      {
        linkName: 'Получения результатов анализов',
        link: `${baseUrlHelix}/poluchenie-rezultatov-analizov/`,
      },
      {
        linkName: 'Напишите нам',
        link: `${baseUrlHelix}/write-us/`,
      },
      {
        linkName: 'Публичный договор',
        link: `${baseUrlHelix}/publichnyj-dogovor-13-04-23/`,
      },
      {
        linkName: 'Обработка персональных данных',
        link: `${baseUrlHelix}/opd/`,
      },
      {
        linkName: 'Контакты',
        link: `${baseUrlHelix}/about/contacts/`,
      },
      {
        linkName: 'Новости',
        link: `${baseUrlHelix}/novosti/`,
      },
      {
        linkName: 'Акции',
        link: `${baseUrlHelix}/discounts/`,
      },
      {
        linkName: 'Адреса и время работы',
        link: `${baseUrlHelix}/centers-addresses/`,
      },
      {
        linkName: 'Анализы на дому',
        link: `${baseUrlHelix}/hometest/`,
      },
      {
        linkName: 'Подготовка к анализам',
        link: `${baseUrlHelix}/preparation/`,
      },
      {
        linkName: 'Порядок обслуживания клиентов',
        link: `${baseUrlHelix}/patients/poryadok-obsluzhivaniya-klientov/`,
      },
      {
        linkName: 'Способы оплаты',
        link: `${baseUrlHelix}/patients/oplata/`,
      },
    ],
  },
];

export const additionalHeaderLinks = [
  {
    title: 'Анализы',
    link: `${preorderingUrl}`,
    links: null,
  },

  {
    title: 'Акции и скидки',
    link: `${baseUrlHelix}/discounts/`,
    links: null,
  },

  {
    title: 'Где сдать',
    links: [
      {
        linkName: 'Минск',
        link: `${baseUrlHelix}/centers-addresses/minsk`,
      },
      {
        linkName: 'Барановичи',
        link: `${baseUrlHelix}/centers-addresses/baranovichi/`,
      },
      {
        linkName: 'Березино*',
        link: `${baseUrlHelix}/centers-addresses/berezino/`,
      },
      {
        linkName: 'Бобруйск',
        link: `${baseUrlHelix}/centers-addresses/bobrujsk/`,
      },
      {
        linkName: 'Борисов',
        link: `${baseUrlHelix}/centers-addresses/borisov/`,
      },
      {
        linkName: 'Брест',
        link: `${baseUrlHelix}/centers-addresses/brest/`,
      },
      {
        linkName: 'Витебск',
        link: `${baseUrlHelix}/centers-addresses/vitebsk`,
      },
      {
        linkName: 'Ганцевичи*',
        link: `${baseUrlHelix}/centers-addresses/gantsevichi/`,
      },
      {
        linkName: 'Гомель',
        link: `${baseUrlHelix}/centers-addresses/gomel/`,
      },
      {
        linkName: 'Глубокое*',
        link: `${baseUrlHelix}/centers-addresses/glubokoe/`,
      },
      {
        linkName: 'Гродно',
        link: `${baseUrlHelix}/centers-addresses/grodno/`,
      },
      {
        linkName: 'Жодино*',
        link: `${baseUrlHelix}/centers-addresses/zhodino/`,
      },
      {
        linkName: 'Жлобин',
        link: `${baseUrlHelix}/centers-addresses/zhlobin/`,
      },
      {
        linkName: 'Заславль*',
        link: `${baseUrlHelix}/centers-addresses/zaslavl/`,
      },
      {
        linkName: 'Иваново*',
        link: `${baseUrlHelix}/centers-addresses/ivanovo/`,
      },
      {
        linkName: 'Лельчицы*',
        link: `${baseUrlHelix}/centers-addresses/lelchitsy/`,
      },
      {
        linkName: 'Кобрин*',
        link: `${baseUrlHelix}/centers-addresses/kobrin/`,
      },
      {
        linkName: 'Лида',
        link: `${baseUrlHelix}/centers-addresses/lida/`,
      },
      {
        linkName: 'Лунинец*',
        link: `${baseUrlHelix}/centers-addresses/luninets/`,
      },
      {
        linkName: 'Марьина Горка*',
        link: `${baseUrlHelix}/centers-addresses/marina-gorka/`,
      },
      {
        linkName: 'Могилев',
        link: `${baseUrlHelix}/centers-addresses/mogilev/`,
      },
      {
        linkName: 'Мозырь',
        link: `${baseUrlHelix}/centers-addresses/mozyr/`,
      },
      {
        linkName: 'Молодечно',
        link: `${baseUrlHelix}/centers-addresses/molodechno/`,
      },
      {
        linkName: 'Новая Боровая*',
        link: `${baseUrlHelix}/centers-addresses/novaja-borovaja/`,
      },
      {
        linkName: 'Новополоцк',
        link: `${baseUrlHelix}/centers-addresses/novopolotsk/`,
      },
      {
        linkName: 'Новогрудок*',
        link: `${baseUrlHelix}/centers-addresses/novogrudok/`,
      },
      {
        linkName: 'Орша',
        link: `${baseUrlHelix}/centers-addresses/orsha/`,
      },
      {
        linkName: 'Пинск',
        link: `${baseUrlHelix}/centers-addresses/pinsk/`,
      },
      {
        linkName: 'Полоцк',
        link: `${baseUrlHelix}/centers-addresses/polotsk/`,
      },
      {
        linkName: 'Речица',
        link: `${baseUrlHelix}/centers-addresses/rechitsa/`,
      },
      {
        linkName: 'Светлогорск',
        link: `${baseUrlHelix}/centers-addresses/svetlogorsk/`,
      },
      {
        linkName: 'Сморгонь*',
        link: `${baseUrlHelix}/centers-addresses/smorgon/`,
      },
      {
        linkName: 'Слуцк',
        link: `${baseUrlHelix}/centers-addresses/slutsk/`,
      },
      {
        linkName: 'Солигорск',
        link: `${baseUrlHelix}/centers-addresses/soligorsk/`,
      },
    ],
  },

  {
    title: 'Выезд на дом',
    link: `${baseUrlHelix}/vyezd/`,
    links: null,
  },

  {
    title: 'Подготовка к анализам',
    link: `${baseUrlHelix}/preparation/`,
    links: null,
  },
  {
    title: 'Услуги',
    links: [
      {
        linkName: 'Прием врача-терапевта',
        link: `${baseUrlHelix}/novosti/1129-konsultatsiya-terapevta/`,
      },
      {
        linkName: 'Взятие биоматериала гинекологом',
        link: `${baseUrlHelix}/novosti/1182-vrach-ginekolog/`,
      },
      {
        linkName: 'Полезные статьи',
        link: `${baseUrlHelix}/diagnostika/`,
      },
    ],
  },
  {
    title: 'Клиентам',
    links: [
      {
        linkName: 'Новости',
        link: `${baseUrlHelix}/news/`,
      },
      {
        linkName: 'Акции и скидки',
        link: `${baseUrlHelix}/discounts/`,
      },
      {
        linkName: 'Адреса и время работы',
        link: `${baseUrlHelix}/centers-addresses/`,
      },
      {
        linkName: 'Анализы на дому',
        link: `${baseUrlHelix}/hometest/`,
      },
      {
        linkName: 'Подготовка к анализам',
        link: `${baseUrlHelix}/preparation/`,
      },
      {
        linkName: 'Порядок обслуживания клиентов',
        link: `${baseUrlHelix}/patients/poryadok-obsluzhivaniya-klientov/`,
      },
      {
        linkName: 'Способы оплаты',
        link: `${baseUrlHelix}/patients/oplata/`,
      },
      {
        linkName: 'Личный кабинет',
        link: `${baseUrlHelix}/lichnyj-kabinet/`,
      },
      {
        linkName: 'Способы получения результатов',
        link: `${baseUrlHelix}/poluchenie-rezultatov-analizov/`,
      },
      {
        linkName: 'Напишите нам',
        link: `${baseUrlHelix}/write-us/`,
      },
      {
        linkName: 'Корпоративным клиентам',
        link: `${baseUrlHelix}/patients/korporativnym-klientam/`,
      },
      {
        linkName: 'Публичный договор',
        link: `${baseUrlHelix}/publichnyj-dogovor-13-04-23/`,
      },
      {
        linkName: 'Обработка персональных данных',
        link: `${baseUrlHelix}/opd/`,
      },
      {
        linkName: 'Вопросы и ответы',
        link: `${baseUrlHelix}/faq/`,
      },
      {
        linkName: 'Контакты',
        link: `${baseUrlHelix}/about/contacts/`,
      },
    ],
  },
  // {
  //   title: 'ПАРТНЕРАМ',
  //   link: `${baseUrl}/medicine/`,
  //   links: null,
  // },
  // {
  //   title: 'ВРАЧАМ',
  //   inRow: true,
  //   link: `${baseUrl}/vracham/`,
  //   links: [
  //     {
  //       linkName: 'Инструкции (сбора биоматериала)',
  //       link: `${baseUrl}/vracham/instruktsii/`,
  //     },
  //     {
  //       linkName: 'Перечень услуг лаборатории',
  //       link: `${baseUrl}/vracham/uslugi/`,
  //     },
  //     {
  //       linkName: 'Образовательный центр',
  //       link: `${baseUrl}/vracham/obrazovatelnyj-tsentr/`,
  //     },
  //     {
  //       linkName: 'Экскурсии в лабораторию',
  //       link: `${baseUrl}/vracham/ekskursii/`,
  //     },
  //     {
  //       linkName: 'Задать вопрос Хеликс',
  //       link: `${baseUrl}/vracham/zadat-vopros-sinevo/`,
  //     },
  //   ],
  // },
  {
    title: 'Работа у нас',
    link: `https://jobs.helix.by/`,
    links: null,
  },
];
