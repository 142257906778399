import React from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  SetMedicalServiceGroup,
  AddMedicalServiceGroupId,
  UnableRedirect,
} from '../../../../__actions/service_actions';
import './breadcrumbs.css';

function toTitleCase(str) {
  const strLow = str.toLowerCase();
  return strLow.replace(
    /\S*/,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

const ServiceBreadCrumbs = (props) => {
  const {
    medicalServiceGroup,
    UnableRedirectAction,
    clearSearch,
    redirect,
  } = props;

  const { title, subTitle } = medicalServiceGroup;
  const dispatch = useDispatch();

  const resetServiceGroupId = () => {
    dispatch(AddMedicalServiceGroupId(null));
  };

  const resetBreadcrumbs = () => {
    dispatch(
      SetMedicalServiceGroup({
        title: '',
      })
    );
  };

  const handleClick = () => {
    clearSearch();
    if (redirect) {
      UnableRedirectAction(false);
    } else {
      resetBreadcrumbs();
      resetServiceGroupId();
    }
  };

  return (
    <div className="title px-2 pl-md-3 pl-lg-4" id="service-breadcrumbs">
      <p className="mb-0">
        <button
          type="button"
          onClick={handleClick}
          className="breadcrumb__button breadcrumb__title px-0"
        >
          Все анализы&nbsp;
        </button>
        {(subTitle && (
          <>
            <span className="breadcrumb__title">
              &gt;
              {toTitleCase(title)}
              &nbsp;
            </span>
            <span className="breadcrumb__sub-title">
              &gt;&nbsp;
              {subTitle}
            </span>
          </>
        ))
          || (title && (
            <span className="breadcrumb__title">
              &gt;&nbsp;
              {toTitleCase(title)}
            </span>
          ))}
      </p>
    </div>
  );
};

ServiceBreadCrumbs.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  UnableRedirectAction: PropTypes.func.isRequired,
  medicalServiceGroup: PropTypes.PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
  }).isRequired,
  redirect: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  medicalServiceGroup: state.service.medicalServiceGroupBreadcrumbs,
  redirect: state.service.redirect,
});

const mapDispatchToProps = (dispatch) => ({
  addMedicalServiceGroupId: (id) => dispatch(AddMedicalServiceGroupId(id)),
  UnableRedirectAction: (bool) => dispatch(UnableRedirect(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceBreadCrumbs);
