import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import Error404 from '../Error/Error404';

const PrivateRoute = ({ Component, role, ...rest }) => (
  <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) => {
      if (role && role === process.env.REACT_APP_ADMIN_KEY) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Component {...props} />;
      }
      return <Error404 />;
    }}
  />
);

PrivateRoute.propTypes = {
  Component: PropTypes.objectOf([PropTypes.func, PropTypes.symbol]).isRequired,
  role: PropTypes.string,
};

PrivateRoute.defaultProps = {
  role: null,
};

const mapState = (state) => ({
  role: state.admin.role,
});

export default connect(mapState)(PrivateRoute);
