import {
  ADD_USER_GENDER,
  ADD_USER_CITY,
  ADD_USER_DATA,
} from './action_const/user_const';

export const AddGender = (gender) => ({
  type: ADD_USER_GENDER,
  payload: gender,
});

export const AddCity = (city) => ({
  type: ADD_USER_CITY,
  payload: city,
});

export const AddUserData = (data) => ({
  type: ADD_USER_DATA,
  payload: data,
});
