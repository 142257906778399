import React, { useState } from 'react';
import {
  useMediaQuery,
  Tooltip,
  Box,
  Button,
  makeStyles,
  Typography,
  ThemeProvider,
  Link,
} from '@material-ui/core';
import Logo from './img/Logo.svg';
import { ReactComponent as CabinetIcon } from './img/CabinetIcon.svg';
import { baseUrlHelix, footerLinks, resultsLoginUrl } from '../../routing/links';
import BasketButton from './BasketButton';
import theme from '../_helper/MuiTheme';

const useStyles = makeStyles((theme) => ({
  logo: {
    padding: '20px 0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      '& img': {
        height: '30px',
      },
    },
  },
  getResultLink: {
    ...getLinkStyles('#FFF', '#1C1C1C'),
    marginRight: '36px',
    padding: '10px 16px',
    boxShadow: '0px 6px 14px 0px rgba(0, 84, 158, 0.2)',
  },
  getCabinetLink: {
    ...getLinkStyles('#00539F', '#FFF'),
    margin: '20px',
    padding: '10px 16px',
    boxShadow: '0px 6px 14px 0px rgba(0, 84, 158, 0.2)',
  },
  cabinetIcon: {
    marginRight: '6px',
  },
  basketButton: {
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      marginRight: '20px',
    },
  },
  loginText: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '170%',
  },
}));

const getLinkStyles = (backgroundColor, color) => ({
  fontFamily: 'Golos',
  padding: '5px 16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
  color: color,
  textTransform: 'initial',
  backgroundColor: backgroundColor,
  transition: 'background-color 0.4s ease',
  '&:hover': {
    backgroundColor: '#00BE6E',
  },
});

const HeaderMid = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box className={classes.logo}>
        <Button component={Link} href={baseUrlHelix} >
            <img src={Logo} alt="Хеликс" />
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          {isMobile && (
            <Box display="flex" alignItems="center">
              <Button
                href="https://results.helix.by/login/code"
                className={classes.getResultLink}
              >
                Получить результаты
              </Button>
              <Button
                href="https://results.helix.by/login"
                className={classes.getCabinetLink}
              >
                Личный кабинет
              </Button>
            </Box>
          )}

          <BasketButton />
          {!isMobile && (
            <Tooltip title="Личный кабинет">
              <Button
                href="https://results.helix.by/login"
                className={classes.getCabinetLink}
              >
                <Box display="flex" alignItems="center">
                  <CabinetIcon className={classes.cabinetIcon} />
                  <Typography className={classes.loginText}>Войти</Typography>
                </Box>
              </Button>
            </Tooltip>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default HeaderMid;
