import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../__actions/admin_actions';
import { servicesPage } from '../../routing/PageRoutes';

const AdminPanel = ({ LogoutAction }) => {
  function Logout() {
    LogoutAction();
  }

  return (
    <div id="admin-page" className="container-fluid pt-5">
      <div className="container card" id="admin-panel">
        <div className="navbar navbar-expand">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav w-100">
              <li className="nav-item active">
                <NavLink className="nav-link" href="#" to={servicesPage}>
                  Главная
                </NavLink>
              </li>
              <li className="nav-item ml-auto" onClick={Logout} onKeyDown={Logout} role="menuitem">
                Выйти
              </li>
            </ul>
          </div>
        </div>
        <h1>Welcome!</h1>
      </div>
    </div>
  );
};
AdminPanel.propTypes = {
  LogoutAction: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch) => ({
  LogoutAction: () => dispatch(logout()),
});

export default connect(null, mapDispatch)(AdminPanel);
