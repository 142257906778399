import React from 'react';
import './content.scss';
import './_helper/styles/buttons.scss';
import PropTypes from 'prop-types';
import { Portal } from '@material-ui/core';
import ServiceBackdrop from './services_page/ServiceBackdrop/ServiceBackdrop';
import ServiceSwap from './Dialog/Swap/ServiceSwap';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Basket from './basket/Basket';
import Calculator from './Calculator/Calculator';

const Content = ({ children }) => (
  <>
    <div className="wrapper tk-content">
      <div className="content">
        <Header />
        <Basket />
        {children}
      </div>
      <Footer />
      <div id="dialog-container" />
    </div>
    <Portal>
      <ServiceBackdrop />
    </Portal>
    <ServiceSwap />
    <Calculator />
  </>
);

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Content;
