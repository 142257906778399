export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';

export function login(value) {
  return {
    type: ADMIN_LOGIN,
    payload: value,
  };
}

export function logout() {
  return {
    type: ADMIN_LOGOUT,
  };
}
