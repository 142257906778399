import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ApiClient from '../../Api/ApiClient';
import {
  AddMedicalServiceGroupId,
  SetMedicalServiceGroup,
  SetServiceGroupError,
  UnableRedirect,
} from '../../__actions/service_actions';
import { MEDICAL_SERVICE_GROUP } from '../_helper/app-const';
import sortMedicalServiceGroup from '../ServiceGroup/sortMedicalServiceGroup';
import MedicalServiceGroupTable from './services_table/MedicalServiceGroupTable';
import ServicesTable from './services_table/ServicesTable';
import Spinner from '../_helper/Spinner';
import useRedirect from './useRedirect';

const getChildrenGroups = (parrent, result) => {
  parrent.children.forEach((child) => {
    if (child?.children?.length > 0) {
      getChildrenGroups(child, result);
    } else {
      result.push(child);
    }
  });
  return result;
};

const getServiceGroups = (groups, id) => {
  const result = [];
  const group = groups.find((i) => i.code === id);

  if (group) {
    if (group?.children?.length > 0) {
      getChildrenGroups(group, result);
    } else {
      result.push(group);
    }
  } else {
    throw new Error('По параметрам поиска не найдено групы услуг.');
  }

  return result;
};

const Redirect = (props) => {
  const [check, setCheck] = useState(true);
  const { isRedirect, unenableRedirect } = useRedirect();
  const [error, setError] = useState({ error: false, message: '' });
  const dispatch = useDispatch();
  const [services, setServices] = useState([]);
  const { match, UnableRedirectAction, serviceGoups } = props;
  const history = useHistory();

  const render = (serviceGroups) => {
    const results = serviceGroups.map(async (group) => (
      <MedicalServiceGroupTable serviceGroupItem={group} key={group.id} />
    ));

    Promise.all(results).then((completed) => {
      setServices(completed);
    });
  };

  const unenableSearch = () => {
    history.push('/services');
  };

  const setRedirect = async () => {
    try {
      setCheck(false);
      const { id } = match.params;
      if (id) {
        UnableRedirectAction(true);

        let groups = serviceGoups;

        if (groups?.length === 0) {
          let request;
          if (match.url.indexOf(MEDICAL_SERVICE_GROUP) !== -1) {
            request = ApiClient.MedicalServiceGroup;
          } else {
            request = ApiClient.ClientServiceGroup;
          }

          groups = await request()
            .then((res) => {
              const sg = sortMedicalServiceGroup(res.data);
              return sg;
            })
            .catch((e) => {
              dispatch(SetServiceGroupError(e.message));
              return [];
            });
        }

        if (groups.length > 0) {
          const result = getServiceGroups(groups, id);

          const servociceGroup = groups.find((i) => i.code === id);

          dispatch(AddMedicalServiceGroupId(servociceGroup.id));
          dispatch(SetMedicalServiceGroup({ title: servociceGroup }));

          render(result);
        } else {
          setError({ error: true, message: 'Ошибка при загрузке груп услуг.' });
        }
      } else if (isRedirect) {
        UnableRedirectAction(false);
      }
    } catch (e) {
      setError({ error: true, message: e.message });
    }
  };

  useEffect(() => {
    setRedirect();
  }, []);

  const handleUnenableRedirect = () => {
    unenableRedirect();
    setCheck(false);
    setError({ error: false, message: '' });
  };

  const result = (
    <Box display="flex" flexDirection="column" gridGap={16}>
      {services}
    </Box>
  );

  if (error.error) {
    return (
      <div className="px-2 text-center my-5">
        <h4 className="color-error mb-3">{error.message}</h4>

        <Button className="btn-primary-filled" onClick={handleUnenableRedirect}>
          Перезагрузить
        </Button>
      </div>
    );
  }

  if (check === true) {
    return <Spinner />;
  }

  if (check === false && isRedirect === true) {
    if (services.length > 0) {
      return result;
    }
    return <Spinner />;
  }
  return <ServicesTable unenableSearch={unenableSearch} match={match} />;
};

const mapStateToProps = (state) => ({
  serviceGroup: state.service.serviceGroup,
  serviceGoups: state.service.sortMedicalServiceGroup,
});

const mapDispatchToProps = (dispatch) => ({
  UnableRedirectAction: (bool, error) => dispatch(UnableRedirect(bool, error)),
});

Redirect.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  UnableRedirectAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Redirect);
