import React, { useEffect } from 'react';
import { Drawer, makeStyles, IconButton, List, Box, Button, Link, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import AccordionHeader from './Accordion/AccordionHeader';
import { menuLinks } from '../../routing/links';
import { ReactComponent as CloseIcon } from './img/ClosedIcon.svg';
import { ReactComponent as LogoIcon } from './img/Logo.svg';
import './Accordion/MobileMenu.scss';

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    zIndex: 9999,
  },
  paper: {
    width: '100%',
  },
  topContainer: {
    backgroundColor: '#EFF0F4',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px',
  },
  defaultButton: {
    fontFamily: 'Golos',
    color: '#313335',
    margin: '12px',
    textTransform: 'inherit',
    ...commonButtonStyles(theme),
    '&:hover': {
      color: '#00BE6E',
    },
  },
  buttonContainer: {
  },
  specialButton: {
    fontWeight: 500,
    lineHeight: '170%',
    letterSpacing: '0.7px',
    textTransform: 'uppercase',
    margin: '20px',
    color: '#00539F',
    ...commonButtonStyles(theme),
  },
  disableScroll: {
    overflow: 'hidden',
  },
  transparentIconButton: {
    backgroundColor: 'transparent',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const commonButtonStyles = (theme) => ({
  fontFamily: 'Golos',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
});

const MobileMenu = ({ open, toggleDrawer }) => {
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      document.body.classList.add(classes.disableScroll);
    } else {
      document.body.classList.remove(classes.disableScroll);
    }

    return () => {
      document.body.classList.remove(classes.disableScroll);
    };
  }, [open]);

  return (
    <Drawer
      classes={{ paper: classes.paper, root: classes.drawerContainer }} 
      anchor="left"
      open={open}
      onClose={toggleDrawer}
      variant="persistent"
    >
      <Box>
        <Box className={classes.topContainer}>
          <IconButton className={classes.transparentIconButton} onClick={toggleDrawer}>
            <LogoIcon />
          </IconButton>
          <IconButton className={classes.transparentIconButton} onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          {menuLinks.map((item) => (
            <AccordionHeader MenuItem={item} key={item.title} />
          ))}
        </Box>
        <Box display="flex" alignItems="flex-start" flexDirection="column" padding={1}>
          <Box className={classes.buttonContainer}>
            <Typography className={classes.specialButton}>О компании</Typography>
          </Box>
          <Box className={classes.buttonContainer}>
            <Button href='https://helix.by/sertifikaty/' className={classes.defaultButton}>Лицензии и сертификаты</Button>
          </Box>
          <Box className={classes.buttonContainer}>
            <Button href='https://helix.by/video-ekskursiya/' className={classes.defaultButton}>Видеоэкскурсии</Button>
          </Box>
          <Box className={classes.buttonContainer}>
            <Button href='https://jobs.helix.by/' className={classes.defaultButton}>Работа у нас</Button>
          </Box>
          <Box className={classes.buttonContainer}>
            <Button href='https://helix.by/about/contacts/' className={classes.defaultButton}>Контакты</Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

MobileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

export default React.memo(MobileMenu);
