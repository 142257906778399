import React, { useState } from 'react';
import {
  Link,
  IconButton,
  Box,
  Typography,
  Grid,
  makeStyles,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import './footer.scss';
import theme from '../_helper/MuiTheme';

import Logo from './img/logo_vertic_white.svg';
import AwardIcon from './img/prem-2.png';

import { ReactComponent as VisaIcon } from './img/Visa.svg';
import { ReactComponent as MasterCardIcon } from './img/MasterCard.svg';
import { ReactComponent as UnionPayIcon } from './img/UnionPay.svg';
import { ReactComponent as ApplePayIcon } from './img/ApplePay.svg';
import { ReactComponent as SamsungPayIcon } from './img/SamsungPay.svg';
import { ReactComponent as BepaidIcon } from './img/Bepaid.svg';
import { ReactComponent as BelkartIcon } from './img/Belkart.svg';

import { ReactComponent as FacebookLogo } from './img/facebook-logo.svg';
import { ReactComponent as InstLogo } from '../_icons/instagram.svg';
import { ReactComponent as VkLogo } from './img/Vk.svg';
import { ReactComponent as YouTubeIcon } from './img/youtube.svg';
import { ReactComponent as TikTokIcon } from './img/tiktok.svg';
import { baseUrlHelix, footerLinks } from '../../routing/links';
import LazyImg from '../_helper/LazyImg';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    margin: '0px',
    padding: '0px',
    fontFamily: 'Golos',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  logo: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  spaceBetweenGridContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
  },
  boxSpacing: {
    marginTop: '44px',
    [theme.breakpoints.down('xs')]: {
      margin: '5px',
    },
  },
  boxSpacing2: {
    marginTop: '67px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '8px',
    },
  },
  servicesClientsListItem: {
    marginTop: '24px',
  },
  sublist: {
    fontFamily: 'Golos',
    paddingTop: '12px',
    color: '#1C1C1C',
    fontSize: '14px',
    fontStyle: 'normal',
    fontFamily: 'Golos',
    fontWeight: 400,
    lineHeight: '150%',
    '&:hover': {
      color: '#00BE6E',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '120%',
    },
  },
  footerLinksContainer: {
    color: '#A4A4B0',
    paddingTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  footerPhoneNum: {
    fontFamily: 'Golos',
    marginTop: '16px',
    color: '#313335',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '140%',
    '&:hover': {
      color: '#00BE6E',
    },
  },
  iconContainer: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
    width: '70px'
    },
  },

  rulesContainer: {
    paddingTop: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
    },
  },
  textHelper: {
    fontFamily: 'Golos',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  ulStyles: {
    listStyle: 'none',
    padding: '0',
  },
  footerLink: {
    fontFamily: 'Galos',
    listStyle: 'none',
    padding: '0px',
    fontSize: '14px',
    color: '#A4A4B0',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    '&:hover': {
      color: '#00BE6E',
    },
  },
  footerContact: {
    backgroundColor: '#EFF0F4',
    paddingBottom: '20px',
  },
  heading: {
    fontFamily: 'Golos',
    lineHeight: '150%',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 600,
  },
  footerWrapper: {
    margin: '0 auto',
    maxWidth: '1300px',
    padding: '0px 18px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 18px 70px',
    },
  },
  mobileIcon: {
    '& svg': {
      height: '24px',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
  },
  titleIcon: {
    marginRight: '8px',
  },
}));

const FooterContent = () => {
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const [open, setopen] = useState(false);

  const toggleDrawer = () => {
    setopen(!open);
  };
  const classes = useStyles();
  return (
    <Box className={classes.footerContact}>
      <Box className={classes.footerWrapper}>
        <Grid
          container
          className={`${classes.gridContainer} ${classes.spaceBetweenGridContainer}`}
        >
          <Grid item xs={12} sm={4} md={3} lg={4} xl={4}>
            <Box
              className={`${classes.boxSpacing} ${
                !isMobile ? classes.gridContainer : ''
              }`}
            >
                <Button component={Link} href={baseUrlHelix} >
                  <LazyImg src={Logo} width={140} height={80} alt="Хеликс" />
                </Button>
            </Box>

            <Box marginLeft={{ xs: 4, sm: 0}} pl={{ xs: 4, sm: 0 }} className={classes.gridContainer}>
              <Typography
                align={!isMobile ? 'center' : 'left'}
                variant="body2"
                className={classes.textHelper}
              >
                ООО «Международная лаборатория Хеликс» <br />
                УНП 191119686
              </Typography>
            </Box>
            <Box
              className={`${classes.boxSpacing2} ${
                !isMobile ? classes.gridContainer : ''
              }`}
            >
              <section>
                <Typography
                  className={!isMobile ? classes.gridContainer : '' }
                  style={{ fontFamily: 'Golos', paddingBottom: '9px'}}
                >
                  Телефон
                </Typography>
                <ul className={classes.ulStyles}>
                  <li>
                    <Link href="tel:7766" className={classes.footerPhoneNum}>
                      7766 А1, МТС, life:)
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="tel:375173388888"
                      className={classes.footerPhoneNum}
                    >
                      +375 (17) 338 88 88
                    </Link>
                  </li>
                </ul>
              </section>
            </Box>
            <Box className={classes.titleContainer}>
              <LazyImg src={AwardIcon} width="100px" height="100px" alt="Award Icon" className={classes.titleIcon} />
              <Box>
                <Typography variant="h6">
                  Медицинская лаборатория №1 в Беларуси*
                </Typography>
                <Typography variant="body2">
                  *по итогам премии номер один 2024
                </Typography>
              </Box>
            </Box>
          </Grid>
          {isMobile && (
            <>
              <Grid
                item
                sm={3}
                md={3}
                lg={3}
                xl={3}
                className={`${classes.boxSpacing}`}
              >
                <Box>
                  <Box className={`${classes.heading}`}>
                    Услуги Клиентам
                  </Box>
                  <ul className={classes.ulStyles}>
                    {footerLinks.ServicesClients.map((item) => (
                      <li
                        key={item.name}
                        className={classes.servicesClientsListItem}
                      >
                        <Link href={item.link} className={classes.sublist}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Grid>
              <Grid
                item
                sm={3}
                md={3}
                lg={3}
                xl={3}
                className={`${classes.boxSpacing}`}
              >
                <Box>
                  <Box variant="h6" className={`${classes.heading}`}>
                    Справочная Информация
                  </Box>
                  <ul className={classes.ulStyles}>
                    {footerLinks.ReferenceInfo.map((item) => (
                      <li
                        key={item.name}
                        className={classes.servicesClientsListItem}
                      >
                        <Link href={item.link} className={classes.sublist}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Grid>
              <Grid
                item
                sm={2}
                md={3}
                lg={2}
                xl={2}
                className={`${classes.boxSpacing}`}
              >
                <Box>
                  <Box className={`${classes.heading}`}>
                    О Компании
                  </Box>
                  <ul className={classes.ulStyles}>
                    {footerLinks.AboutUs.map((item) => (
                      <li
                        key={item.name}
                        className={classes.servicesClientsListItem}
                      >
                        <Link href={item.link} className={classes.sublist}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        <Box>
          <Grid container  className={classes.gridContainer}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <section>
                <Typography className={classes.gridContainer}  style={{ paddingBottom: '14px'}}>
                  Социальные сети
                </Typography>
                <Box display="flex" className={classes.gridContainer}>
                  <IconButton
                    component={Link}
                    href={footerLinks.facebook.link}
                    size="small"
                    title={footerLinks.facebook.name}
                    name="Facebook Helix"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookLogo />
                  </IconButton>
                  <IconButton
                    component={Link}
                    href={footerLinks.inst.link}
                    size="small"
                    title={footerLinks.inst.name}
                    name="Instagram Helix"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstLogo />
                  </IconButton>
                  <IconButton
                    component={Link}
                    href={footerLinks.vk.link}
                    size="small"
                    title={footerLinks.vk.name}
                    name="Vkontakte Helix"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <VkLogo />
                  </IconButton>
                  <IconButton
                    component={Link}
                    href={footerLinks.tiktok.link}
                    size="small"
                    title={footerLinks.tiktok.name}
                    name="TikTok Helix"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TikTokIcon />
                  </IconButton>
                  <IconButton
                    component={Link}
                    href={footerLinks.youtube.link}
                    size="small"
                    title={footerLinks.youtube.name}
                    name="YouTube Helix"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <YouTubeIcon />
                  </IconButton>
                </Box>

                <Typography
                  className={`${classes.gridContainer} ${classes.footerLinksContainer} ${classes.textHelper}`}
                >
                  2016-{new Date().getFullYear()} © Хеликс
                </Typography>
              </section>
            </Grid>
            <Grid item xs={12} md={8} lg={8} xl={8}>
              <Box className={classes.rulesContainer}>
                {isMobile && (
                  <Box display="flex" justifyContent="space-between">
                    <Box className={classes.iconContainer}>
                      <VisaIcon />
                    </Box>
                    <Box className={classes.iconContainer}>
                      <MasterCardIcon />
                    </Box>
                    <Box className={classes.iconContainer}>
                      <UnionPayIcon />
                    </Box>
                    <Box className={classes.iconContainer}>
                      <ApplePayIcon />
                    </Box>
                    <Box className={classes.iconContainer}>
                      <SamsungPayIcon />
                    </Box>
                    <Box className={classes.iconContainer}>
                      <BelkartIcon />
                    </Box>
                    <Box className={classes.iconContainer}>
                      <BepaidIcon />
                    </Box>
                  </Box>
                )}
                <Box className={classes.footerLinksContainer}>
                  <Link
                    href={footerLinks.publicContract.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.footerLink}
                  >
                    {footerLinks.publicContract.name}
                  </Link>
                  <Link
                    href={footerLinks.personalDataProcessing.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.footerLink}
                  >
                    {footerLinks.personalDataProcessing.name}
                  </Link>
                  <Link
                    href={footerLinks.customerService.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.footerLink}
                  >
                    {footerLinks.customerService.name}
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default FooterContent;
