import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { useRef } from 'react';
import './StyledInput.scss';

const StyledInput = ({
  value,
  onChange,
  startAdornment,
  className,
  label,
  helperText,
  InputProps,
  ref,
  readOnly,
  error,
  ...props
}) => {
  const input = (
    <TextField
      fullWidth
      className={clsx('styled-input', className, {
        'start-adorment': startAdornment,
        'styled-input-error': error,
        'styled-input-read-only': readOnly,
      })}
      variant="filled"
      error={Boolean(error)}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
        readOnly: readOnly,
      }}
      helperText={helperText || error?.message}
      value={value}
      onChange={onChange}
      label={label}
      {...props}
    />
  );
  if (startAdornment) {
    return (
      <Grid
        container
        alignItems="center"
        className={clsx('styled-input-grid', className, {
          'styled-input-error': error,
        })}
      >
        <Grid item className="styled-input-icon">
          {startAdornment}
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          {input}
        </Grid>
      </Grid>
    );
  }
  return input;
};

export default StyledInput;
