import React from 'react';
import './services-table.scss';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import ApiClient from '../../../Api/ApiClient';
import ServiceInfo from './Accordion';
import { MEDICAL_GROUP } from '../../_helper/app-const';
import AddServiceButton from './AddServiceButton';
import { fetchStatus } from '../../_helper/fetchStatus';

class MedicalServiceGroupTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: fetchStatus.IDLE,
      pageSize: 20,
      pageNumber: 1,
      hasNextPage: false,
      services: [],
    };
    this.handleClickPageChange = this.handleClickPageChange.bind(this);
  }

  componentDidMount() {
    const { status } = this.state;
    if (status === fetchStatus.IDLE) {
      this.AddServices();
    }
  }

  componentDidUpdate(prevProps) {
    const { gender } = this.props;

    if (prevProps.gender !== gender) {
      this.GetServices();
    }
  }

  handleClickPageChange(event) {
    const { pageNumber } = this.state;
    this.AddServices(pageNumber + 1);

    if (event.target.className.indexOf('MuiButton-label') !== -1) {
      event.target.parentNode.blur();
    } else {
      event.target.blur();
    }
  }

  async GetServices() {
    const { serviceGroupItem, serviceGroup, gender } = this.props;
    const { pageSize } = this.state;
    this.setState({ status: fetchStatus.PENDING });

    let clientServiceGroupId = null;
    let medicalServiceGroupId = null;

    if (serviceGroup === MEDICAL_GROUP) {
      medicalServiceGroupId = serviceGroupItem.id;
    } else {
      clientServiceGroupId = serviceGroupItem.id;
    }

    await ApiClient.Services(
      1,
      pageSize,
      gender,
      clientServiceGroupId,
      medicalServiceGroupId
    )
      .then((res) => {
        const response = res.data;
        if (res && response.isSuccess) {
          this.setState({
            status: fetchStatus.FULLFILED,
            hasNextPage: response.page.hasNextPage,
            pageNumber: response.page.pageNumber,
            services: response.services,
          });
        } else {
          throw new Error(
            response?.statusMessage || 'По вашему запросу анализов не найдено. Попробуйте изменить запрос или обратитесь за консультацией по телефону 7766'
          );
        }
      })
      .catch((e) => {
        this.setState({
          status: fetchStatus.REJECTED,
          message: e.message,
        });
      });
  }

  async AddServices(pageNumber = 1) {
    const { serviceGroupItem, serviceGroup, gender } = this.props;
    const { pageSize, services } = this.state;

    this.setState({ status: fetchStatus.PENDING });

    let clientServiceGroupId = null;
    let medicalServiceGroupId = null;

    if (serviceGroup === MEDICAL_GROUP) {
      medicalServiceGroupId = serviceGroupItem.id;
    } else {
      clientServiceGroupId = serviceGroupItem.id;
    }

    await ApiClient.Services(
      pageNumber,
      pageSize,
      gender,
      clientServiceGroupId,
      medicalServiceGroupId
    )
      .then((res) => {
        const response = res.data;
        if (res !== undefined && response.isSuccess) {
          this.setState({
            status: fetchStatus.FULLFILED,
            hasNextPage: response.page.hasNextPage,
            pageNumber: response.page.pageNumber,
            services: services.concat(response.services),
          });
        }
      })
      .catch((e) => {
        this.setState({
          status: fetchStatus.REJECTED,
          message: e.message,
        });
      });
  }

  render() {
    const { serviceGroupItem } = this.props;
    const {
      hasNextPage, services, status, message
    } = this.state;

    const error = status === fetchStatus.REJECTED;
    const success = status === fetchStatus.FULLFILED;
    const loading = status === fetchStatus.PENDING;

    return (
      <>
        {loading ? (
          <div className="d-flex justify-content-center py-3 py-md-5">
            <div className="loader-four">
              <div className="loader-four__preloader" />
            </div>
          </div>
        ) : (
          <div className="table-wrap px-0 px-md-2">
            <h2 className="service-table__title">{serviceGroupItem.name}</h2>

            {error || services.length === 0 ? (
              <h2 className="service-table__error mb-5">
                {message || 'По вашему запросу анализов не найдено. Попробуйте изменить запрос или обратитесь за консультацией по телефону 7766'}
              </h2>
            ) : null}

            {success && services.length > 0 && (
              <table id="service-table">
                <thead>
                  <tr>
                    <td className="pl-3 pl-md-4" style={{ width: '50%' }}>
                      ПЕРЕЧЕНЬ АНАЛИЗОВ
                    </td>
                    <td className="">
                      ЦЕНА
                      <br />
                      (byn)
                    </td>
                    <td className="px-4 px-md-0">
                      <span className="d-sm-none">СРОК</span>
                      <span className="d-none d-sm-block">
                        СРОК ВЫПОЛНЕНИЯ
                        <br />
                        (рабочих дней)
                      </span>
                    </td>
                    <td className="d-none d-md-block">
                      ДОБАВИТЬ В
                      <br />
                      КОРЗИНУ
                    </td>
                  </tr>
                </thead>
                <tbody className="service-table__body">
                  {services.map((service, index) => (
                    <React.Fragment key={service.id}>
                      <tr>
                        <td
                          aria-label="Перечень анализов"
                          className="pl-3 pl-md-4"
                        >
                          {`${service.code} / ${service.name}`}
                        </td>
                        <td>{service.price}</td>
                        <td aria-label="Срок выполнения (рабочих дней)">
                          {service.workingDays}
                          <span className="service-table__button--hidden ">
                            {service.isOnlineOrder && (
                              <AddServiceButton service={service} />
                            )}
                          </span>
                        </td>
                        <td
                          aria-label="Добавить в корзину"
                          className="service-table__button"
                        >
                          {service.isOnlineOrder && (
                            <AddServiceButton service={service} />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="pl-3 pl-md-5">
                          <ServiceInfo service={service} index={index} />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
                {hasNextPage && (
                  <tfoot>
                    <tr>
                      <td className="service-table__footer" colSpan="5">
                        <div className="d-flex justify-content-center">
                          <Button
                            className="btn-primary-filled"
                            onClick={this.handleClickPageChange}
                          >
                            Загрузить еще
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            )}
          </div>
        )}
      </>
    );
  }
}

MedicalServiceGroupTable.propTypes = {
  serviceGroup: PropTypes.string.isRequired,
  serviceGroupItem: PropTypes.shape({
    code: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    parentGroupId: PropTypes.number,
  }).isRequired,
  gender: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  gender: state.user.gender,
  medicalServiceGroupId: state.service.medicalServiceGroupId,
  hasNextPage: state.service.hasNextPage,
  parrentServiceCodes: state.basket.parrentServiceCodes,
  allServiceCodes: state.basket.allServiceCodes,
  serviceGroup: state.service.serviceGroup,
});

export default connect(mapStateToProps)(MedicalServiceGroupTable);
