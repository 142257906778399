import { makeStyles } from '@material-ui/core';

const serviceGrpoupStyles = makeStyles(() => ({
  button: {
    fontFamily: ['Lato', 'sans-serif'],
    margin: '6px 0',
    justifyContent: 'start',
    textTransform: 'initial',
    width: '100%',
    color: '#00539F',
    '& .MuiButton-label': {
      fontWeight: '400',
    },
    '&.selected_item': {
      '& .MuiButton-label': {
        color: '#00539F',
        textDecoration: 'underline',
      },
    },
    '&.accordion-item, &.nested-list': {
      display: 'inline-flex',
      margin: 0,
      '& .MuiButton-label': {
        color: '#2E3B52',
      },
    },
  },
  title: {
    paddingLeft: 6,
    paddingRight: 6,
    margin: 0,
    minHeight: 30,
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 30,
      margin: 0,
    },
    color: '#00539F',
    '&.nested-list': {
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
      '& .MuiButton-text': {
        paddingRight: 0,
        paddingLeft: 0,
        margin: 0,
      },
    },
  },
  accordion: {
    boxShadow: 'none',
    '& .MuiAccordionDetails-root': {
      paddingTop: 0,
    },
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
  },
}));

export default serviceGrpoupStyles;
