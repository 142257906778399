import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  state: false,
  message: '',
  open: false,
};

const ServiceConflictReducer = createSlice({
  name: 'service-conflict',
  initialState,
  reducers: {
    setOpenDialog(state, action) {
      const { message, conflict } = action.payload;
      state.value = conflict || false;
      state.open = true;
      state.message = message;
    },
    resolveConflict(state) {
      state.value = false;
      state.open = false;
      state.message = '';
    },
  },
});

export const { setOpenDialog, resolveConflict } = ServiceConflictReducer.actions;
export default ServiceConflictReducer.reducer;
