const male = 'M';
const female = 'F';
const uknown = 3;
const noSet = 'U';

const all = new Map([
  [male, 1],
  [female, 2],
]);

export default { male, female, uknown, all, noSet };
