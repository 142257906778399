export const IDLE = 'idle';
export const PENDING = 'pending';
export const FULLFILED = 'fullfiled';
export const REJECTED = 'rejected';

export const fetchStatus = {
  IDLE,
  PENDING,
  FULLFILED,
  REJECTED,
};
