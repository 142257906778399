import React, { useRef,useState, useEffect } from 'react';
import { ReactComponent as ArrowIcon } from './img/Arrow.svg';
import Dropdown from './Dropdown';

const MenuItemsHeaderTop = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.link && items.links ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {window.innerWidth < 960 && depthLevel === 0 ? (
              items.title
            ) : (
              <a href={items.link} target="_blank" rel="noopener noreferrer">
                    <div className="scrollable-container">
                      {items.title}
                    </div>
              </a>
            )}

            {depthLevel > 0 && window.innerWidth < 960 ? null : depthLevel > 0 && window.innerWidth > 960 ? (
              <span>&raquo;</span>
            ) : (
              <ArrowIcon className="arrow" />
            )}
          </button>
          <Dropdown depthLevel={depthLevel} submenus={items.links} dropdown={dropdown} />
        </>
      ) : !items.link && items.links ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.title} {depthLevel > 0 ? <span>&raquo;</span> : <ArrowIcon className="arrow" />}
          </button>
          <Dropdown depthLevel={depthLevel} submenus={items.links} dropdown={dropdown} />
        </>
      ) : (
        <a href={items.link} target="_blank" rel="noopener noreferrer">
          {items.title}
        </a>
      )}

    </li>
  );
};

export default MenuItemsHeaderTop;
