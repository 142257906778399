import React from 'react';
import './services.scss';
import PropTypes from 'prop-types';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import SelectInfo from './select_user_info/SelectInfo';
import Redirect from './Redirect';
import InfoBlock from '../info_block/InfoBlock';
import AboutOrderHelperText from './AboutOrderHelperText';
import ServiceGroup from '../ServiceGroup/ServiceGroup';
import ServiceAddingBlock from './ServiceAddingBlock';

const Services = (props) => {
  const { match, crumbs } = props;

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <div className="services-container px-sm-4 px-xl-0 mb-xl-5">
        <div className="row no-gutters justify-content-center">
          <div className="col-12 col-xl-3 d-flex justify-content-center">
            <ServiceGroup />
          </div>
          <div className="col-12 col-xl-9 service-wrap">
            <div className="services-block">
              <SelectInfo match={match} />
              <div className="px-2">
                <div className="pt-2 px-2 px-md-3 px-lg-4">
                  <AboutOrderHelperText />
                </div>
              </div>
              <Redirect match={match} />
            </div>
            <InfoBlock />
            <ServiceAddingBlock />
          </div>
        </div>
      </div>
    </>
  );
};

Services.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      Component: PropTypes.elementType,
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
};

export default Services;
