import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import { AddGender } from '../../__actions/user_actions';
import StyledInput from '../_inputs/StyledInput';
import genderHelper from '../_helper/genderHelper';

const GenderInput = ({ startAdornment }) => {
  const gender = useSelector((state) => state.user.gender);
  const dispatch = useDispatch();

  const [message, setMessage] = useState('');

  const handleChange = (event) => {
    dispatch(AddGender(event.target.value));
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const validateProps = {
    helperText: message,
    error: message.length > 0,
    onFocus: () => {
      if (gender === genderHelper.noSet) {
        setMessage('Пожалуйста, выберите пол!');
      } else if (message) setMessage('');
    },
  };

  if (isMobile) {
    return (
      <StyledInput
        label="Пол"
        select
        startAdornment={startAdornment}
        SelectProps={{ native: true }}
        id="select-gender"
        {...validateProps}
        value={gender}
        onChange={handleChange}
      >
        <option value={genderHelper.noSet} disabled>
          Выберите пол
        </option>
        <option value={genderHelper.female}>Женский</option>
        <option value={genderHelper.male}>Мужской</option>
      </StyledInput>
    );
  }

  return (
    <StyledInput
      label="Пол"
      select
      id="select-gender"
      startAdornment={startAdornment}
      {...validateProps}
      value={gender}
      onChange={handleChange}
    >
      <MenuItem value={genderHelper.noSet} disabled>
        Выберите пол
      </MenuItem>
      <MenuItem value={genderHelper.female}>Женский</MenuItem>
      <MenuItem value={genderHelper.male}>Мужской</MenuItem>
    </StyledInput>
  );
};

GenderInput.propTypes = {
  startAdornment: PropTypes.elementType,
};

GenderInput.defaultProps = {
  startAdornment: undefined,
};

export default GenderInput;
