import { format } from 'date-fns';

export function getDateFull() {
  return format(new Date(), 'dd.MM.yyyy HH:mm:ss');
}

export function formatedDate(value) {
  const date = new Date(value);
  return format(date, 'dd/MM/yyyy');
}

export function convertDate(date) {
  return format(date, 'yyyyMMdd');
}
