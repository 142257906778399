import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatus } from '../../_helper/fetchStatus';
import genderHelper from '../../_helper/genderHelper';
import useService from '../../_helper/scripts/useService';
import { ReactComponent as Basket } from './img/basket.svg';

const AddServiceButton = ({ service }) => {
  const [disabled, setDisabled] = useState(false);

  const status = useSelector((state) => state.addingService.status);
  const gender = useSelector((state) => state.user.gender);
  const loading = status === fetchStatus.PENDING;

  const { isServiceAdded, AddService } = useService();

  const enableBtn = () => setDisabled(false);
  const isAdded = isServiceAdded(service.code);
  const tooltip = isAdded ? 'Удалить услугу' : 'Добавить услугу';

  const handleClickOnService = () => {
    if (gender === genderHelper.noSet) {
      const input = document.querySelector('#select-gender');
      input.focus();
    } else {
      setDisabled(true);
      AddService(service.code, enableBtn);
    }
  };

  return (
    <div className="d-flex justify-content-start pl-md-4">
      {loading && disabled ? (
        <CircularProgress className="color-primary" size={32} />
      ) : (
        <Tooltip title={tooltip}>
          <IconButton
            disabled={disabled || loading}
            onClick={handleClickOnService}
            className={clsx('service-table__flask', isAdded && 'added')}
            aria-label="добавить/удалить анализы в корзину"
          >
            <Basket />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

AddServiceButton.propTypes = {
  service: PropTypes.shape({
    code: PropTypes.string,
    comment: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    remark: PropTypes.string,
    workingDays: PropTypes.number,
  }).isRequired,
};

export default AddServiceButton;
