import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import FirstPrev from './img/item_first_prev.svg';
import SecondPrev from './img/item_second_prev.svg';
import ThirdSelect from './img/item_third_select.svg';
import FourNext from './img/item_four_next.svg';
import { checkoutPage, servicesPage } from '../../routing/PageRoutes';

const BreadcrumbsConfirm = () => {
  const divRef = useRef();

  const handleClick = () => {
    const child = divRef.current.firstChild.scrollWidth * 2 - 120;
    divRef.current.scrollLeft = child;
  };

  return (
    <div className="wrap-breadcrumbs">
      <ul
        ref={divRef}
        className="breadcrumbs mb-2 list-group list-group-horizontal justify-content-start justify-content-md-around px-2"
        onLoad={handleClick}
      >
        <li className="list-group-item">
          <Link to={servicesPage}>
            <img src={FirstPrev} alt="Выбор анализов предыдущий шаг" title="Выбор анализов предыдущий шаг" />
          </Link>
        </li>
        <li className="list-group-item">
          <Link to={checkoutPage}>
            <img src={SecondPrev} alt="Оформление заказа предыдущий шаг" title="Оформление заказа предыдущий шаг" />
          </Link>
        </li>
        <li className="list-group-item">
          <img src={ThirdSelect} alt="Подтверждение даных текущий шаг" title="Подтверждение даных текущий шаг" />
        </li>
        <li className="list-group-item d-flex px-2">
          <img src={FourNext} alt="Оплата заказа следующий шаг" title="Оплата заказа следующий шаг" />
          <span className="pl-2">Оплата заказа</span>
        </li>
      </ul>
    </div>
  );
};

export default BreadcrumbsConfirm;
