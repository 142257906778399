import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useParams } from 'react-router-dom';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../../basket/img/close.svg';
import breakpointsHelper from '../../../_helper/breakpointsHelper';
import StyledInput from '../../../_inputs/StyledInput';

import { ReactComponent as SearchIcon } from '../../../_icons/search.svg';
import { SetSearchTerm } from '../../../../__actions/service_actions';
import { useIsMount } from '../../../_helper/scripts/useIsMout';
import useRedirect from '../../useRedirect';

const ServiceSearch = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const term = useSelector((state) => state.service?.term || '');
  const { unenableRedirect } = useRedirect();
  const isMount = useIsMount();

  const [searchValue, setSearchValue] = useState(term);
  const [debouncedSearchValue] = useDebounce(searchValue, 500, {
    maxWait: 2000,
  });

  const handleChange = (e) => {
    unenableRedirect();
    setSearchValue(e.currentTarget.value);
  };

  const handleClear = () => {
    setSearchValue('');
  };

  const icon = searchValue?.length > 0 ? (
    <Tooltip title="Очистить поиск">
      <IconButton onClick={handleClear}>
        <CloseIcon width={16} className="color-primary" />
      </IconButton>
    </Tooltip>
  ) : (
    <Box p={2}>
      <SearchIcon className="color-primary " />
    </Box>
  );

  const placeholderText = window.innerWidth > breakpointsHelper.md
    ? 'Введите код или название услуги'
    : 'Поиск';

  const helperText = searchValue?.length !== 0 && searchValue?.length < 3
    ? 'Поиск от 3-х символов'
    : '';

  useEffect(() => {
    if (!isMount) {
      if (
        debouncedSearchValue.length > 2
        || debouncedSearchValue.length === 0
      ) {
        dispatch(SetSearchTerm(debouncedSearchValue.trim()));
      }
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (params?.code && params?.code?.length > 2) {
      setSearchValue(params.code);
    }
  }, [params]);

  useEffect(() => {
    if (!isMount) {
      if (term?.length === 0 && searchValue.length !== 0) {
        setSearchValue('');
      }
    }
  }, [term]);

  return (
    <StyledInput
      value={searchValue}
      onFocus={unenableRedirect}
      className="search"
      helperText={helperText}
      onChange={handleChange}
      InputProps={{ placeholder: placeholderText, endAdornment: icon }}
    />
  );
};

export default ServiceSearch;
