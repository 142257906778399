import { createSlice } from '@reduxjs/toolkit';
import { fetchStatus } from '../components/_helper/fetchStatus';
import { fetchByService } from './BasketReducer';

const initialState = {
  status: fetchStatus.IDLE,
};

const addingServiceSlice = createSlice({
  name: 'adding-service-reducer',
  initialState,
  extraReducers: {
    [fetchByService.pending]: (state) => {
      state.status = fetchStatus.PENDING;
    },
    [fetchByService.fulfilled]: (state) => {
      state.status = fetchStatus.FULLFILED;
    },
    [fetchByService.rejected]: (state) => {
      state.status = fetchStatus.REJECTED;
    },
  },
});

export default addingServiceSlice.reducer;
