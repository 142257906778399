const initialState = {
  token: '',
};

const ADD_TOKEN = 'recaptcha/add_token';

const AddToken = (token) => ({
  type: ADD_TOKEN,
  payload: token,
});

const recaptchaReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};
export { AddToken };
export default recaptchaReducer;
