import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogTitle
} from '@material-ui/core';
import PropTypes from 'prop-types';
import '../../Dialog/Dialog.scss';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkoutPage } from '../../../routing/PageRoutes';
import { fetchByService } from '../../../__reducer/BasketReducer';

const DialogSpecial = ({
  open, onClose, closeBasket, promotion
}) => {
  const dialogTitleRef = useRef();
  const [disabled, setDisabled] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const parrentCodes = useSelector((state) => state.basket.parrentServiceCodes);
  const allCodes = useSelector((state) => state.basket.allServiceCodes);

  const styleCode = () => {
    const regexp = new RegExp('\\b[^.]\\d{4,}|\\d+,\\d+ BYN', 'g');
    if (
      dialogTitleRef.current !== undefined
      && dialogTitleRef.current !== null
    ) {
      const styledHtml = dialogTitleRef.current.innerHTML.replace(
        regexp,
        '<span class="dialog__highlight">$&</span>'
      );
      dialogTitleRef.current.innerHTML = styledHtml;
    }
  };

  const setSpecialService = () => {
    window.localStorage.setItem(
      `specialService${promotion?.id}`,
      true
    );
  };

  const handleClose = () => {
    onClose();
    closeBasket();
  };

  const handleSubmit = async () => {
    setDisabled(true);

    const codes = [...allCodes, promotion.serviceCode];

    const newParrentCd = [
      ...parrentCodes,
      promotion.serviceCode,
    ].filter((item) => codes.indexOf(item) !== -1);

    const response = await dispatch(
      fetchByService({ checkCd: codes, calculatorCd: newParrentCd })
    ).unwrap();

    if (!response.conflict) {
      history.push(checkoutPage);
      handleClose();
    }
  };

  const handleCancel = () => {
    setSpecialService();
    history.push(checkoutPage);
    handleClose();
  };

  useEffect(() => {}, []);

  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={onClose}
      onEnter={() => styleCode()}
    >
      <DialogTitle
        ref={dialogTitleRef}
        className="dialog__title"
        disableTypography
      >
        {promotion?.message}
      </DialogTitle>
      <DialogTitle className="dialog__title" disableTypography>
        Добавить в корзину?
      </DialogTitle>
      <DialogActions className="dialog__actions">
        <Button
          disabled={disabled}
          className="dialog__btn-cancel"
          onClick={handleCancel}
        >
          Отмена
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={disabled}
          className="dialog__btn-submit"
        >
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogSpecial.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeBasket: PropTypes.func.isRequired,
};

export default DialogSpecial;
