import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeIsOpenBasket, fetchByService } from '../../../__reducer/BasketReducer';
import { resolveConflict } from '../../../__reducer/ServiceConflictReducer';
import { fetchStatus } from '../../_helper/fetchStatus';
import getSwapServices from '../../_helper/scripts/getSwapServices';
import '../Dialog.scss';

const ServiceSwapDialog = ({ open }) => {
  const dispatch = useDispatch();
  const dialogRef = useRef();

  const conflict = useSelector((state) => state.conflict.value);
  const message = useSelector((state) => state.conflict.message);
  const parrentCodes = useSelector((state) => state.basket.parrentServiceCodes);

  const status = useSelector((state) => state.basket.status);

  const loading = status === fetchStatus.PENDING;

  const styleCode = () => {
    const regexp = new RegExp('\\b\\d+', 'g');
    if (dialogRef.current !== undefined && dialogRef.current !== null) {
      const styledHtml = dialogRef.current.innerHTML.replace(
        regexp,
        '<span class="highlight">$&</span>'
      );
      dialogRef.current.innerHTML = styledHtml;
    }
  };

  const handleClose = () => {
    dispatch(resolveConflict());
  };

  const handleSwap = () => {
    const [serviceToSwap, servicesToDelete] = getSwapServices(conflict);

    let newServices = parrentCodes.slice();

    servicesToDelete.forEach((s) => {
      const index = newServices.indexOf(s);
      newServices = [
        ...newServices.slice(0, index),
        ...newServices.slice(index + 1),
      ];
    });

    const codes = [...newServices, serviceToSwap];

    dispatch(fetchByService({ checkCd: codes, calculatorCd: codes })).then(() => {
      dispatch(changeIsOpenBasket());
      handleClose();
    });
  };

  const buttons = conflict ? (
    <DialogActions className="dialog__actions">
      <Button
        disabled={loading}
        style={{ color: '#2E3B52' }}
        onClick={handleClose}
      >
        Отменить
      </Button>
      <Button
        disabled={loading}
        className="dialog__btn-submit"
        color="primary"
        onClick={handleSwap}
      >
        Добавить
      </Button>
    </DialogActions>
  ) : (
    <DialogActions className="dialog__actions">
      <Button
        color="primary"
        className="dialog__btn-submit"
        onClick={handleClose}
      >
        ОК
      </Button>
    </DialogActions>
  );

  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={!loading ? handleClose : undefined}
      onEnter={() => styleCode()}
    >
      <DialogTitle
        className="dialog__title"
        disableTypography
        ref={dialogRef}
      >
        {message}
      </DialogTitle>

      {buttons}
    </Dialog>
  );
};

export default ServiceSwapDialog;
