import React from 'react';
import './info-block.scss';

const InfoBlock = () => (
  <section className="info-block mx-auto">
    <div className="row no-gutters mb-4 mb-lg-5 justify-content-between aling-items-end">
      <div className="col-12 mb-4 mb-md-0 col-sm-5 pr-sm-2 col-xl-6 pr-lg-3">
        <div className="card b-info-block">
          <article className="info-block-text card-body">
            <p>
              Справочная информация об услугах, ценах и графиках работы:&nbsp;
              <span className="info-block-code">
                <a href="tel:7766">7766</a>
              </span>
              &nbsp;А1, МТС, life:)&nbsp;
              <span className="info-block-code">
                <a href="tel:+375173388888">+375173388888</a>
              </span>
              &nbsp;Белтелеком.
            </p>
          </article>
        </div>
      </div>
      <div className="col-12 col-sm-7 pl-sm-2 pl-md-4 col-xl-6 pl-lg-3">
        <div className="card b-info-block">
          <article className="info-block-text card-body flex-column">
            <p>
              Техническая поддержка онлайн заказов:&nbsp;
              <span className="info-block-code">
                <a href="tel:+375292330570">+375292330570</a>
              </span>
            </p>
            <p>
              Анализы на дому: запись по телефону&nbsp;
              <span className="info-block-code">
                <a href="tel:+375293640074">+375293640074</a>
              </span>
            </p>
          </article>
        </div>
      </div>
      <div className="col-12 pt-4">
        <div className="b-info-block--full card">
          <article className="info-block-text card-body">
            <p className="mb-2">
              Сроки выполнения анализов указаны без учета дня взятия
              биоматериала, выходных и праздничных дней. Если анализы сданы не в
              Минске, срок готовности результата увеличивается на 1 день (время
              на транспортировку биоматериала в лабораторию) в следующих
              случаях:
            </p>
            <ul className="info-block__list">
              <li>- анализы с заявленным сроком выполнения 1 рабочий день;</li>
              <li>- анализы, выполняемые методом ПЦР.</li>
            </ul>
          </article>
        </div>
      </div>
    </div>
  </section>
);

export default InfoBlock;
