import React, { PureComponent } from 'react';
import './select_info.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReactComponent as OpenSidebarIcon } from './img/Group 442.svg';
import ServiceTabs from './ServiceTabs/ServiceTabs';
import {
  ChangeIsOpenSidebar,
  SetSearchTerm,
} from '../../../__actions/service_actions';
import Hint from '../../Hint/Hint';
import ServiceBreadcrumbs from './ServiceBreadcrumbs/ServiceBreadcrumbs';
import breakpointsHelper from '../../_helper/breakpointsHelper';
import ServiceSearch from './Search/ServiceSearch';
import GenderInput from '../../GenderInput/GenderInput';

class SelectInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hint: !JSON.parse(window.localStorage.getItem('disable-hint')),
    };

    this.sidebarBtnRef = React.createRef();
    this.handleClickSidebar = this.handleClickSidebar.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.EnableSidebarBtn = this.EnableSidebarBtn.bind(this);
  }

  handleClickSidebar() {
    const { OpenSidebar } = this.props;
    OpenSidebar(true);
  }

  EnableSidebarBtn() {
    this.setState({ hint: false });
  }

  clearSearch() {
    const { term, ClearSearchAction } = this.props;

    if (term?.length > 0) {
      ClearSearchAction();
    }
  }

  render() {
    const { match } = this.props;
    const { hint } = this.state;
    return (
      <>
        <div className="d-flex justify-content-end">
          <ServiceTabs match={match} clearSearch={this.clearSearch} />
        </div>
        <div className="select-info p-2">
          <div className="row no-gutters pt-4">
            <div className="col-12 d-flex align-items-center">
              <button
                disabled={hint}
                name="Open sidebar"
                type="button"
                id="SibebarBtn"
                className="btn sidebar__button-open-sidebar p-0"
                onClick={this.handleClickSidebar}
              >
                <OpenSidebarIcon title="Открыть меню анализов" />
              </button>
              {hint && window.innerWidth < breakpointsHelper.lg && (
                <Hint enableBtn={this.EnableSidebarBtn} />
              )}

              <ServiceBreadcrumbs clearSearch={this.clearSearch} />
            </div>
          </div>
          <div className="row no-gutters select-info__row px-2 px-md-3 px-lg-4 pt-4 mb-2">
            <div className="col-12 mb-2 col-sm-4 col-md-3  order-2 order-md-0">
              <GenderInput />
            </div>
            <div className="col-12 col-sm-8 col-md-9 pl-sm-4 pl-md-5 order-3 order-md-0">
              <ServiceSearch />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  term: state.service.term,
});

const mapDipatchToProps = (dispatch) => ({
  OpenSidebar: (bool) => dispatch(ChangeIsOpenSidebar(bool)),
  ClearSearchAction: () => dispatch(SetSearchTerm('')),
});

SelectInfo.propTypes = {
  term: PropTypes.string.isRequired,
  OpenSidebar: PropTypes.func.isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  ClearSearchAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDipatchToProps)(SelectInfo);
