import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import ErrorIcon from './img/Error404.svg';
import './error.css';
import '../_helper/button.css';
import { servicesPage } from '../../routing/PageRoutes';

const Error404 = () => (
  <div className="container mb-5" id="error404">
    <div className="d-flex justify-content-center flex-column align-items-center">
      <h4>СТРАНИЦА НЕ НАЙДЕНА</h4>
      <p className="mb-3">
        К сожалению, такой страницы не существует. Вероятно, она была удалена,
        либо ее здесь никогда не было.
      </p>
      <Button component={Link} to={servicesPage} className="btn-primary-filled">
        На главную
      </Button>
      <img src={ErrorIcon} alt="Ошибка 404" className="img-fluid" />
    </div>
  </div>
);

export default Error404;
