import { additionalHeaderLinks} from '../../routing/links';
import MenuItemsHeaderBottom from './MenuItemsHeaderBottom';

const HeaderBottomNavbar = () => {
  return (
    <nav>
      <ul className="menus">
        {additionalHeaderLinks.map((menu, index) => {
          return <MenuItemsHeaderBottom items={menu} key={index} />;
        })}
      </ul>
    </nav>
  );
};

export default HeaderBottomNavbar;