import React, { Suspense, useEffect } from 'react';
import ReactGa from 'react-ga';
import PropTypes from 'prop-types';
import { Switch, Route, useLocation } from 'react-router-dom';
import routes from './App/routing/routes';
import ScrollToTop from './App/components/_helper/ScrollToTop';
import Content from './App/components/Content';
import AdminPanel from './App/components/admin/AdminPanel';
import PrivateRoute from './App/components/admin/PrivateRoute';

ReactGa.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
  cookieFlags: 'same-site=none;secure;httpOnly',
});
const Error404 = React.lazy(() => import('./App/components/Error/Error404'));

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGa.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <Suspense fallback={null}>
      <ScrollToTop />
      <Switch>
        {routes.map(({ path, name, Component }) => (
          <Route
            exact
            path={path}
            key={name}
            render={(props) => {
              const crumbs = routes
                .filter((item) => props.match.path.includes(item.path))
                .map(({ path, ...rest }) => ({
                  path: Object.keys(props.match.params).length
                    ? Object.keys(props.match.params).reduce(
                      (path, param) => path.replace(
                        `:${param}`,
                        props.match.params[param]
                      ),
                      path
                    )
                    : path,
                  ...rest,
                }));

              return (
                <Content>
                  <Component crumbs={crumbs} {...props} />
                </Content>
              );
            }}
          />
        ))}
        <PrivateRoute path="/admin" Component={AdminPanel} />
        <Route path="*" render={() => <Error404 />} />
      </Switch>
    </Suspense>
  );
}

App.propTypes = {
  match: PropTypes.func,
};

App.defaultProps = {
  match: () => {},
};

export default App;
