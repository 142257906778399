import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiClient from '../Api/ApiClient';
import errorHelper from '../components/_helper/errorHelper';
import { fetchStatus } from '../components/_helper/fetchStatus';

const initialState = {
  cardNumber: '',
  isVerificated: false,
  status: fetchStatus.IDLE,
  message: '',
  services: [],
  total: 0,
  withDiscount: false,
};

const fetchByCheckDiscount = createAsyncThunk(
  'discount/check',
  async (data = { fetchData: {}, token: '' }, { rejectWithValue }) => {
    const { fetchData, token } = data;
    return ApiClient.CheckDiscount(fetchData, token)
      .then((res) => {
        if (res.data) {
          const response = res.data;
          if (response.isSuccess) {
            let priceWithDiscount = 0;

            response.priceBookService.forEach((item) => {
              priceWithDiscount += item.finalPrice;
            });

            return {
              cardNumber: fetchData.cardNumber,
              message: `Карта ${fetchData.cardNumber} успешно применена`,
              totalPrice: priceWithDiscount.toFixed(2),
              services: response.priceBookService,
            };
          }
          return rejectWithValue({
            message: response.statusMessage,
          });
        }
        return rejectWithValue({
          message: errorHelper.tryRequestLater,
        });
      })
      .catch((e) =>
        rejectWithValue({
          message: e.message,
        })
      );
  }
);

const GetDiscountServices = createAsyncThunk(
  'discount/byContract',
  async (codes, { rejectWithValue }) =>
    ApiClient.GetPriceV2(codes)
      .then((res) => {
        if (res?.data) {
          if (res.data.isSuccess) {
            let priceWithDiscount = 0;

            res.data.priceBookService.forEach((service) => {
              priceWithDiscount += service.finalPrice;
            });

            return {
              services: res.data.priceBookService || [],
              totalPrice: priceWithDiscount.toFixed(2),
            };
          }
          throw new Error(res.data.statusMessage);
        }
        throw new Error(errorHelper.tryRequestLater);
      })
      .catch((e) => rejectWithValue(e.message))
);

const discountReducer = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    setCardNumber(state, action) {
      state.cardNumber = action.payload;
      state.isVerificated = false;
    },
    setStatus(state, action) {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    clearVerification(state) {
      state.isVerificated = false;
    },
    resetTotal(state) {
      state.services = [];
      state.total = 0;
    },
    setWithDiscount(state, action) {
      state.withDiscount = action.payload;
    },
  },
  extraReducers: {
    [fetchByCheckDiscount.pending]: (state) => {
      state.status = fetchStatus.PENDING;
    },
    [fetchByCheckDiscount.fulfilled]: (state, action) => {
      state.status = fetchStatus.FULLFILED;
      state.cardNumber = action.payload.cardNumber;
      state.message = action.payload.message;
      state.services = action.payload.services;
      state.total = action.payload.totalPrice;
      state.isVerificated = true;
    },
    [fetchByCheckDiscount.rejected]: (state, action) => {
      state.status = fetchStatus.REJECTED;
      state.cardNumber = '';
      state.message = action.payload.message;
      state.isVerificated = false;
    },
    [GetDiscountServices.pending]: () => {},
    [GetDiscountServices.fulfilled]: (state, action) => {
      state.services = action.payload.services;
      state.total = action.payload.totalPrice;
      state.isVerificated = false;
    },
    [GetDiscountServices.rejected]: (state, action) => {
      state.status = fetchStatus.REJECTED;
      state.message = action.payload;
      state.services = [];
      state.total = 0;
      state.isVerificated = false;
    },
  },
});

export { fetchByCheckDiscount, GetDiscountServices };
export const {
  setCardNumber,
  setStatus,
  clearVerification,
  resetTotal,
  setWithDiscount,
} = discountReducer.actions;
export default discountReducer.reducer;
