import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { ChangeIsOpenSidebar } from '../../__actions/service_actions';
import { ReactComponent as Triang } from './img/triang.svg';
import serviceGrpoupStyles from './serviceGroupStyles';

const ServiceGroupSubItem = ({
  serviceGroupItem,
  changeServiceGroup,
  selectedServiceGroupId,
  dispatch,
}) => {
  const classes = serviceGrpoupStyles();

  return (
    <>
      <Accordion
        classes={{ root: classes.accordion }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={<Triang />}
          classes={{ root: classes.title }}
          className="nested-list"
        >
          {serviceGroupItem.name}
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {serviceGroupItem.children.map((item) => (
              <Button
                classes={{ root: classes.button }}
                className={clsx('nested-list', {
                  selected_item: item.id === selectedServiceGroupId,
                })}
                key={item.id}
                onClick={() => {
                  dispatch(ChangeIsOpenSidebar());
                  changeServiceGroup(item, serviceGroupItem);
                }}
              >
                {item.name}
              </Button>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
ServiceGroupSubItem.propTypes = {
  serviceGroupItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string,
    name: PropTypes.string,
    parrentGroupId: PropTypes.number,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string,
        name: PropTypes.string,
        parrentGroupId: PropTypes.number,
      })
    ),
  }).isRequired,
  changeServiceGroup: PropTypes.func.isRequired,
  selectedServiceGroupId: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
};

ServiceGroupSubItem.defaultProps = {
  selectedServiceGroupId: null,
};

const mapStateToProps = (state) => ({
  selectedServiceGroupId: state.service.medicalServiceGroupId,
});

export default connect(mapStateToProps)(ServiceGroupSubItem);
