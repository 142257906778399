import React from 'react';
import { useSelector } from 'react-redux';
import ServiceSwapDialog from './ServiceSwapDialog';

const ServiceSwap = () => {
  const open = useSelector((state) => state.conflict.open);

  return open ? <ServiceSwapDialog open={open} /> : null;
};

export default ServiceSwap;
