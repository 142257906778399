import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CommentIcon from './img/comment.svg';
import { ReactComponent as AttentionIcon } from './img/attention.svg';
import { ReactComponent as InfoIcon } from './img/info.svg';

const ServiceInfo = ({ service, index }) => {
  const remarkRef = useRef(false);
  const commentRef = useRef(false);

  function Remark(remark, comment) {
    if (
      remark !== null
      && remark !== undefined
      && remark
      && remarkRef.current
      && remarkRef
    ) {
      remarkRef.current.innerHTML = remark;
    }

    if (
      comment !== null
      && comment !== undefined
      && comment
      && commentRef.current
      && commentRef
    ) {
      commentRef.current.innerHTML = comment;
    }
  }

  useEffect(() => {
    Remark(service.remark, service.comment);
  }, [service.remark, service.comment]);

  function DescPattern(text) {
    return (
      <tr>
        <td className="accordion__desc py-2">
          <span className="px-3">
            <InfoIcon />
          </span>
          <p>{text}</p>
        </td>
      </tr>
    );
  }

  function Description() {
    const { description, results } = service;

    if (description !== null && description.length > 0) {
      if (results !== null && results.length > 0) {
        return DescPattern(`${description} ${results}`);
      }
      return DescPattern(description);
    }
    if (results !== null && results.length > 0) {
      return DescPattern(results);
    }
  }

  return (
    <div id={`accordion_${index}`}>
      {(service.remark
        || service.comment
        || service.description
        || service.results) && (
        <table className="service-table__accordion">
          <tbody>
            {service.comment !== null && service.comment.length > 0 && (
              <tr>
                <td className="accordion__comment py-2">
                  <div className="d-flex flex-row">
                    <div className="d-flex flex-column justify-content-between px-3">
                      <AttentionIcon />
                    </div>
                    <div
                      style={{ color: 'error' }}
                      className="d-flex align-items-center"
                    >
                      <p ref={commentRef}>{service.comment}</p>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {service.remark !== null && service.remark.length > 0 && (
              <tr>
                <td className="accordion__remark py-2">
                  <span className="px-3">
                    <img
                      src={CommentIcon}
                      alt=""
                      loading="lazy"
                      width="24px"
                      height="24px"
                    />
                  </span>
                  <p ref={remarkRef} id="remark">
                    &nbsp;
                  </p>
                </td>
              </tr>
            )}
            {Description()}
          </tbody>
        </table>
      )}
    </div>
  );
};

ServiceInfo.propTypes = {
  service: PropTypes.shape({
    code: PropTypes.string,
    comment: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    remark: PropTypes.string,
    results: PropTypes.string,
    workingDays: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default ServiceInfo;
