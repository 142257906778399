import { Button, withStyles } from '@material-ui/core';

const StyledButton = withStyles({
  root: {
    minWidth: 192,
  },
  text: {
    color: '#00539F',
    fontSize: 14,
    lineHeight: '17px',
    textTransform: 'initial',
    justifyContent: (props) => (props.fullWidth ? 'start' : 'center'),
    paddingTop: 0,
    paddingBottom: 0,
  },
  contained: {
    background: '#00539F',
    borderRadius: 27,
    height: 48,
    color: '#FFFFFF',
    '&:hover': {
      background: '#002955',
      color: '#FFFFFF',
    },
    '& .MuiButton-label': {
      fontWeight: 400,
      textTransform: 'initial',
    },
  },
  outlined: {
    border: '2px solid #00539F',
    minHeight: (props) => (props.height === 'medium' ? 48 : 35),
    borderRadius: 27,
    color: '#00539F',
    '& .MuiButton-label': {
      fontWeight: 400,
      textTransform: 'initial',
    },
    '&:hover': {
      background: '#00539F',
      color: '#FFFFFF',
    },
  },
})(Button);

export default StyledButton;
