import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { servicesPage } from '../../routing/PageRoutes';
import FirstPrev from './img/item_first_prev.svg';
import SecondSelect from './img/item_second_select.svg';
import ThirdNext from './img/item_third_next.svg';
import FourNext from './img/item_four_next.svg';

const BreadcrumbsCheckout = () => {
  const divRef = useRef();

  const handleClick = () => {
    const child = divRef.current.firstChild.scrollWidth - 100;
    divRef.current.scrollLeft = child;
  };

  return (
    <div className="wrap-breadcrumbs">
      <ul
        ref={divRef}
        className="breadcrumbs mb-2 list-group list-group-horizontal justify-content-start justify-content-md-around px-2"
        onLoad={handleClick}
      >
        <li className="list-group-item">
          <Link to={servicesPage}>
            <img src={FirstPrev} alt="Выбор анализов предыдущий шаг" title="Выбор анализов предыдущий шаг" />
          </Link>
        </li>
        <li className="list-group-item">
          <img src={SecondSelect} alt="Оформление заказа текущий шаг" title="Оформление заказа текущий шаг" />
        </li>
        <li className="list-group-item">
          <img src={ThirdNext} alt="Подтверждение даных следующий шаг" title="Подтверждение даных следующий шаг" />
        </li>
        <li className="list-group-item d-flex px-2">
          <img src={FourNext} alt="Оплата заказа следующий шаг" />
          <span className="pl-2">Оплата заказа</span>
        </li>
      </ul>
    </div>
  );
};

export default BreadcrumbsCheckout;
