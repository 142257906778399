import { IconButton } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ApiClient from '../../Api/ApiClient';
import {
  ChangeIsOpenSidebar,
  SetMedicalServiceGroup,
  AddMedicalServiceGroupId,
  UnableRedirect,
  AddSortedMedicalServiceGroup,
  SetServiceGroupError,
} from '../../__actions/service_actions';
import { MEDICAL_GROUP } from '../_helper/app-const';
import CloseIcon from './img/close.svg';
import ServiceGroupItem from './ServiceGroupItem';
import sortMedicalServiceGroup from './sortMedicalServiceGroup';

const ServiceGroup = ({
  isRedirect,
  isVisible,
  serviceGroups,
  serviceGroupType,
  dispatch,
}) => {
  const handleOpen = () => {
    dispatch(ChangeIsOpenSidebar());
  };

  const setServiceGroup = async (selectedServiceGroup, parentServiceGroup) => {
    if (isRedirect) {
      dispatch(UnableRedirect(false));
    }

    let breadcrumbs;
    if (parentServiceGroup) {
      breadcrumbs = {
        title: parentServiceGroup,
        subTitle: selectedServiceGroup,
      };
    } else {
      breadcrumbs = { title: selectedServiceGroup };
    }

    dispatch(AddMedicalServiceGroupId(selectedServiceGroup.id));
    dispatch(SetMedicalServiceGroup(breadcrumbs));
  };

  const hadServices = serviceGroups?.length > 0;

  const list = hadServices
    ? serviceGroups.map((item) => (
      <ServiceGroupItem
        key={item.id}
        serviceGroupItem={item}
        changeServiceGroup={setServiceGroup}
      />
    ))
    : null;

  useEffect(() => {
    const getServiceGroupAsync = async () => {
      let request;
      if (serviceGroupType === MEDICAL_GROUP) {
        request = ApiClient.MedicalServiceGroup;
      } else {
        request = ApiClient.ClientServiceGroup;
      }

      await request()
        .then((res) => {
          if (res && res.data?.length > 0) {
            const services = sortMedicalServiceGroup(res.data);
            dispatch(AddSortedMedicalServiceGroup(services));
          } else {
            throw new Error('Произошла ошибка при получении группы услуг');
          }
        })
        .catch((e) => {
          dispatch(SetServiceGroupError(e.message));
        });
    };
    if (serviceGroupType) {
      getServiceGroupAsync();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceGroupType]);

  return (
    <div className={clsx('sidebar mb-5', isVisible && 'visible')}>
      <IconButton onClick={handleOpen} className="sidebar__close-img">
        <img src={CloseIcon} alt="" />
      </IconButton>
      {list}
    </div>
  );
};

const mapState = (state) => ({
  isRedirect: state.service.redirect,
  isVisible: state.service.isVisible,
  serviceGroups: state.service.sortMedicalServiceGroup,
  serviceGroupType: state.service.serviceGroup,
  error: state.service.serviceGroupError,
});

ServiceGroup.propTypes = {
  isRedirect: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  serviceGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string,
      name: PropTypes.string,
      parrentGroupId: PropTypes.number,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          code: PropTypes.string,
          name: PropTypes.string,
          parrentGroupId: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  serviceGroupType: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapState)(ServiceGroup);
