import naturalSort from 'javascript-natural-sort';

const sortWithParrent = (array, parent) => {
  const serviceGroup = [];

  array.forEach((service) => {
    try {
      const { parentGroupId, id } = service;

      if (parentGroupId === parent.id) {
        serviceGroup[id] = service;
      } else {
        const parentItem = serviceGroup.find(
          (item) => item?.id === parentGroupId
        );

        parentItem.children.push(service);
      }
    } catch {
      // eslint-disable-next-line no-console
      console.error(
        `Произошла ошибка при загрузке id-${service?.id} - ${service?.name} - parentGroupId-${service?.parentGroupId}`
      );
    }
  });

  return serviceGroup.filter((n) => n);
};

const sortPlease = (array) => {
  const serviceGroup = [];
  const regex = /(\d{1,3}\.)+$/;

  array.forEach((item) => {
    try {
      const { parentGroupId, id, code } = item;

      if (regex.test(code)) {
        item.children = [];
      }

      if (parentGroupId === id) {
        serviceGroup[code] = item;
        return;
      }

      if (parentGroupId !== id) {
        const parrentCode = code.match(/\d{1,3}\./g)[0];
        const parrentItem = serviceGroup[parrentCode];

        parrentItem.children.push(item);
      }
    } catch {
      // eslint-disable-next-line no-console
      console.error(
        `Произошла ошибка при загрузке id-${item?.id} - ${item?.name} - parentGroupId-${item?.parentGroupId}`
      );
    }
  });
  return serviceGroup;
};

function sortMedicalServiceGroup(medicalServiceGroup) {
  const services = medicalServiceGroup;

  services.sort((a, b) => naturalSort(a.code, b.code));

  const serviceGroup = sortPlease(services);

  const serviceGroupArray = Object.values(serviceGroup);

  serviceGroupArray.forEach((item) => {
    if (item.children.length > 0) {
      item.children = sortWithParrent(item.children, item);
    }
  });

  return serviceGroupArray;
}

export default sortMedicalServiceGroup;
