import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { servicesPage } from '../../../routing/PageRoutes';
import StyledButton from '../StyledTextButton';
import './basket-empty.css';
import { changeIsOpenBasket } from '../../../__reducer/BasketReducer';

const BasketEmpty = () => {
  const dispatch = useDispatch();

  const handleCloseBasket = () => {
    dispatch(changeIsOpenBasket());
  };
  return (
    <>
      <div className="basket-empty pt-5">
        <div className="basket-empty__caption mb-4">ВАША КОРЗИНА ПУСТА</div>
        <div className="basket-empty__text mb-5">
          Для оформления заказа онлайн добавьте анализы в корзину
        </div>
        <StyledButton
          component={Link}
          to={servicesPage}
          onClick={handleCloseBasket}
          variant="contained"
        >
          Добавить анализы
        </StyledButton>
      </div>
      <div className="basket__footer">
        <p>
          ✓Стоимость анализов указана без учета дополнительных услуг по
          регистрации заказа и взятию биоматериала.
        </p>
        <p>✓Оплата заказа производится по ценам, действующим на день оплаты.</p>
        <p>
          ✓Заказ, самостоятельно оформленный на сайте, не может быть оплачен
          частично или полностью страховой компанией.
        </p>
        <p>
          ✓Заказ действителен в течение 30 дней с момента создания. Заказы,
          содержащие акционную услугу действительны в течение срока действия
          акции.
        </p>
      </div>
    </>
  );
};

export default BasketEmpty;
