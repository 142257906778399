import { IconButton, useMediaQuery } from '@material-ui/core';
import React from 'react';
import theme from '../_helper/MuiTheme';

const HeaderButton = ({ children, ...props }) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <IconButton size={isDesktop ? 'medium' : 'small'} {...props}>
      {children}
    </IconButton>
  );
};

export default HeaderButton;
