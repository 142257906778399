import vars from './var';
import { getDateFull } from '../components/_helper/dates';

const userData = {
  UserName: process.env.REACT_APP_NAME,
  Password: vars,
  Date: getDateFull(),
};

const contractId = Number.parseInt(process.env.REACT_APP_CONTRACT, 10);

export { userData, contractId };
