import ApiClient from '../Api/ApiClient';
import {
  GET_PAGINATION_INFO,
  MEDICAL_SERV_GROUP_BREADCRUMBS,
  ADD_SORT_MEDICAL_SERVICE_GROUP,
  IS_OPEN_SIDEBAR,
  ADD_SERVICES,
  ADD_SERVICES_FROM_SERVICE_GROUP,
  ADD_SEARCH_SERVICES,
  ADD_MEDICAL_SERVICE_GROUP_ID,
  ADD_SERVICE_GROUP,
  REDIRECT,
  LOADING,
  serviceConst,
} from './action_const/service_const';

export const GetPaginationInfo = (page) => ({
  type: GET_PAGINATION_INFO,
  payload: page,
});

export const SetMedicalServiceGroup = (title) => ({
  type: MEDICAL_SERV_GROUP_BREADCRUMBS,
  payload: title,
});

export const AddSortedMedicalServiceGroup = (services) => ({
  type: ADD_SORT_MEDICAL_SERVICE_GROUP,
  payload: services,
});

export const ChangeIsOpenSidebar = () => ({
  type: IS_OPEN_SIDEBAR,
});

export const AddServices = (services) => ({
  type: ADD_SERVICES,
  payload: services,
});

export const AddServicesFromServiceGroup = (services, error = false) => ({
  type: ADD_SERVICES_FROM_SERVICE_GROUP,
  payload: services,
  error,
});

export const AddSearchServices = (services, error = false) => ({
  type: ADD_SEARCH_SERVICES,
  payload: services,
  error,
});

export const AddMedicalServiceGroupId = (medicalServiceGroupId) => ({
  type: ADD_MEDICAL_SERVICE_GROUP_ID,
  payload: medicalServiceGroupId,
});

export const AddServiceGroup = (type) => ({
  type: ADD_SERVICE_GROUP,
  payload: type,
});

export const UnableRedirect = (bool, error = false) => ({
  type: REDIRECT,
  payload: bool,
  error,
});

export const SetLoading = (bool) => ({
  type: LOADING,
  payload: bool,
});

export const SetServiceGroupError = (message) => ({
  type: serviceConst.SERVICE_GROUP_ERROR,
  payload: message,
});

export const SetSearchTerm = (term) => ({
  type: serviceConst.SEARCH_TERM,
  payload: term,
});
