import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import genderHelper from '../_helper/genderHelper';
import { useIsMount } from '../_helper/scripts/useIsMout';
import useService from '../_helper/scripts/useService';

const CalculatorController = () => {
  const { fetchByServices, ClearBasket } = useService();

  const parrentCodes = useSelector((state) => state.basket.parrentServiceCodes);
  const gender = useSelector((state) => state.user.gender);
  const addedServices = useSelector((state) => state.basket.addedServices);

  const isMount = useIsMount();

  useEffect(() => {
    const filterByCurrentGender = () => addedServices
      .filter(
        (s) => s.genderId === genderHelper.uknown
            || s.genderId === genderHelper.all.get(gender)
      )
      .map((i) => i.code);

    if (addedServices.length > 0 && !isMount) {
      const codes = filterByCurrentGender();
      const filterddParrentCodes = parrentCodes.filter(
        (c) => codes.indexOf(c) !== -1
      );

      if (filterddParrentCodes.length > 0) {
        fetchByServices(codes, filterddParrentCodes);
      } else {
        ClearBasket();
      }
    }
  }, [gender]);

  return null;
};

export default CalculatorController;
