/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchStatus } from '../components/_helper/fetchStatus';
import Service from '../components/_helper/Service';
import { setOpenDialog } from './ServiceConflictReducer';

const initialState = {
  isOpen: false,
  addedServices: [],
  parrentServiceCodes: [],
  allServiceCodes: [],
  total: 0,
  totalWithDiscount: undefined,
};

const fetchByService = createAsyncThunk(
  'basket/check-add-services',
  async (data, thunk) => {
    const { checkCd, calculatorCd } = data;

    try {
      const checkResult = await Service.CheckServices(checkCd);
      if (checkResult.isSuccess) {
        const gender = await thunk.getState().user.gender;
        const parrentServiceCodes = await thunk.getState().basket
          .parrentServiceCodes;
        const services = await Service.Calculator(gender, calculatorCd);

        let totalPrice = 0;
        let totalPriceWithDiscount = 0;
        const serviceCodes = [];

        services.forEach((item) => {
          totalPrice += item.price;
          totalPriceWithDiscount += item.finalPrice;
          serviceCodes.push(item.code);
        });

        const sortedServices = services.sort((a) => {
          if (parrentServiceCodes.includes(a.code)) {
            return -1;
          }
          return 1;
        });

        return {
          services,
          parrentCd: calculatorCd,
          sortedServices,
          serviceCodes,
          totalPrice: totalPrice.toFixed(2),
          totalPriceWithDiscount: totalPriceWithDiscount.toFixed(2),
        };
      }
      if (checkResult.conflict) {
        thunk.dispatch(
          setOpenDialog({
            message: checkResult.message,
            conflict: checkResult.conflict,
          })
        );
        return thunk.rejectWithValue({
          message: checkResult.message,
          conflict: checkResult.conflict,
        });
      }
      throw new Error(checkResult.message);
    } catch (e) {
      thunk.dispatch(
        setOpenDialog({
          message: e.message
        })
      );
      return thunk.rejectWithValue({ message: e.message });
    }
  }
);

const basketReducer = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    changeIsOpenBasket(state) {
      state.isOpen = !state.isOpen;
    },
    ClearBasket(state) {
      state.total = 0;
      state.totalWithDiscount = 0;
      state.addedServices = [];
      state.allServiceCodes = [];
      state.parrentServiceCodes = [];
      state.status = fetchStatus.IDLE;
    },
    addParrentServiceCodes(state, action) {
      state.parrentServiceCodes = action.payload;
    },
  },
  extraReducers: {
    [fetchByService.fulfilled]: (state, action) => {
      const {
        parrentCd,
        serviceCodes,
        sortedServices,
        totalPrice,
        totalPriceWithDiscount,
      } = action.payload;

      state.allServiceCodes = serviceCodes;
      state.addedServices = sortedServices;
      state.total = totalPrice;
      state.totalWithDiscount = totalPriceWithDiscount;
      state.parrentServiceCodes = parrentCd;
    },
  },
});

export { fetchByService };
export const { ClearBasket, addParrentServiceCodes, changeIsOpenBasket } =
  basketReducer.actions;
export default basketReducer.reducer;
