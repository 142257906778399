import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

const LeavePageBlocker = ({ when }) => {
  const message = 'Обновить сайт? Внесенные изменения, возможно, не будут сохранены!';

  useEffect(() => {
    if (!when) return () => {};

    const beforeUnloadCallback = (event) => {
      event.preventDefault();
      return (event.returnValue = message);
    };

    window.addEventListener('beforeunload', beforeUnloadCallback);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
    };
  }, [when, message]);

  return <Prompt when={when} message={message} />;
};

export default LeavePageBlocker;
