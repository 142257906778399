import { headerLinks} from '../../routing/links';
import MenuItemsHeaderTop from './MenuItemsHeaderTop';

const HeaderTopNavbar = () => {
  return (
    <nav>
      <ul className="menus menus-top">
        {headerLinks.map((menu, index) => {
          return <MenuItemsHeaderTop items={menu} key={index} />;
        })}
      </ul>
    </nav>
  );
};

export default HeaderTopNavbar;