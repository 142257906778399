import { useDispatch, useSelector } from 'react-redux';
import {
  changeIsOpenBasket,
  ClearBasket as ClearBasketAction,
  fetchByService,
} from '../../../__reducer/BasketReducer';
import { setOpenDialog } from '../../../__reducer/ServiceConflictReducer';

const useService = () => {
  const dispatch = useDispatch();
  const parrentCodes = useSelector((state) => state.basket.parrentServiceCodes);
  const allCodes = useSelector((state) => state.basket.allServiceCodes);

  const isServiceAdded = (code) => allCodes.indexOf(code) !== -1;

  const RemoveService = async (code) => {
    try {
      const index = allCodes.indexOf(code);

      if (index !== -1) {
        if (parrentCodes.length > 1) {
          const codes = [
            ...allCodes.slice(0, index),
            ...allCodes.slice(index + 1),
          ];

          const newParrentCd = codes.filter(
            (cd) => parrentCodes.indexOf(cd) !== -1
          );
          await dispatch(
            fetchByService({ checkCd: codes, calculatorCd: newParrentCd })
          );
        } else {
          dispatch(ClearBasketAction());
        }
      }
    } catch (e) {
      dispatch(
        setOpenDialog({
          message: e.message,
        })
      );
    }
  };

  const AddService = async (code, enableButton) => {
    try {
      const index = allCodes.indexOf(code);

      if (index === -1) {
        const codes = [...allCodes, code];
        const newParrentCd = [...parrentCodes, code].filter(
          (item) => codes.indexOf(item) !== -1
        );

        try {
          const response = await dispatch(
            fetchByService({ checkCd: codes, calculatorCd: newParrentCd })
          ).unwrap();
          if (!response.conflict) {
            dispatch(changeIsOpenBasket());
          }
          if (enableButton) {
            enableButton();
          }
        } catch (e) {
          if (enableButton) {
            enableButton();
          }
        }
      } else {
        await RemoveService(code).finally(() => {
          if (enableButton) {
            enableButton();
          }
        });
      }
    } catch (e) {
      enableButton();
      dispatch(
        setOpenDialog({
          message: e.message,
        })
      );
    }
  };

  const ClearBasket = () => {
    dispatch(ClearBasketAction());
  };

  const fetchByServices = (checkCd, calculatorCd) => {
    dispatch(fetchByService({ checkCd, calculatorCd }));
  };

  return {
    AddService,
    RemoveService,
    isServiceAdded,
    fetchByServices,
    ClearBasket,
  };
};

export default useService;
