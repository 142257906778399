import React from 'react';
import './breadcrumbs.css';
import PropTypes from 'prop-types';
import {
  servicesPage,
  confirmPage,
  checkoutPage,
} from '../../routing/PageRoutes';
import BreadcrumbsServices from './BreadcrumbsServices';
import BreadcrumbsConfirm from './BreadcrumbsConfirm';
import BreadcrumbsCheckout from './BreadcrumbsCheckout';

const Breadcrumbs = ({ crumbs }) => (
  <>
    {crumbs.map(({ name, path }, key) => (key + 1 === crumbs.length ? (
      <div key={name} className=" pt-sm-4 pt-md-5 mb-4 mb-md-5">
        {(path === servicesPage
            || path === '/'
            || path.includes('search')
            || path.includes('medicalServiceGroupId')
            || path.includes('clientServiceGroupId')) && <BreadcrumbsServices />}
        {path === checkoutPage && <BreadcrumbsCheckout />}
        {path === confirmPage && <BreadcrumbsConfirm />}
      </div>
    ) : null))}
  </>
);

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      Component: PropTypes.Breadcrumbs,
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
};

export default Breadcrumbs;
