import React from 'react';
import FirstSelect from './img/item_first_select.svg';
import SecondNext from './img/item_second_next.svg';
import ThirdNext from './img/item_third_next.svg';
import FourNext from './img/item_four_next.svg';

const BreadcrumbsServices = () => (
  <div className="wrap-breadcrumbs">
    <ul className="breadcrumbs mb-4 list-group list-group-horizontal justify-content-start justify-content-md-around px-2">
      <li className="list-group-item">
        <img src={FirstSelect} alt="Выбор анализов текущий шаг" title="Выбор анализов текущий шаг" />
      </li>
      <li className="list-group-item">
        <img src={SecondNext} alt="Оформление заказа следующий шаг" title="Оформление заказа следующий шаг" />
      </li>
      <li className="list-group-item">
        <img src={ThirdNext} alt="Подтверждение даных следующий шаг" title="Подтверждение даных следующий шаг" />
      </li>
      <li className="list-group-item d-flex px-2">
        <img src={FourNext} alt="Оплата заказа следующий шаг" title="Оплата заказа следующий шаг" />
        <span className="pl-2">Оплата заказа</span>
      </li>
    </ul>
  </div>
);

export default BreadcrumbsServices;
