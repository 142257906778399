import React from 'react';
import './Dropdown.scss';
import { Link} from '@material-ui/core';

const Dropdown = ({ submenus, dropdown, multiColumn }) => {
  return (
    <ul className={`dropdown ${dropdown ? 'show' : ''} ${multiColumn ? 'multi-column' : ''} `}>
      {submenus.map((links, index) => (
        <Link key={index} href={links.link} className="dropdown-links">
          {links.linkName}
        </Link>
      ))}
    </ul>
  );
};

export default Dropdown;
