import React, { useRef, useState } from 'react';
import HeaderBottomNavbar from '../DropdownMenu/HeaderBottomNavbar';

const HeaderBot = () => {

  return (
       <div className="nav-area">
          <HeaderBottomNavbar />
     </div>
  );
};

export default HeaderBot;
