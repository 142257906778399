import React, { useState } from 'react';
import {
  BottomNavigationAction,
  BottomNavigation,
  Button,
  useMediaQuery,
  Link,
  makeStyles,
  Box,
  Typography,
  IconButton,
} from '@material-ui/core';
import { ReactComponent as MenuIcon } from './img/menu_icon.svg';
import { ReactComponent as NoteIcon } from './img/Note.svg';
import { ReactComponent as AmbulanceIcon } from './img/reduction.svg';
import { ReactComponent as ReductionIcon } from './img/Ambulance.svg';
import { footerLinks } from '../../routing/links';
import MobileMenu from '../Header/MobileMenu';
import theme from '../_helper/MuiTheme';
import LinkItem from './LinkItem';

const useStyles = makeStyles((theme) => ({
  mobileMenuBtn: {
    backgroundColor: '#00BE6E',
    width: "60px",
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#007a47',
    },
  },
  mobileBtn: {},
  root: {
    width: '100%',
    overflow: 'hidden',
    height: '70px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: '#00539F',
  },
}));

const MobileBottomNavigation = () => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const [open, setopen] = useState(false);

  const toggleDrawer = () => {
    setopen(!open);
  };

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <LinkItem
            icon={NoteIcon}
            label={footerLinks.results.name}
            href={footerLinks.results.link}
          />
          <LinkItem
            icon={AmbulanceIcon}
            label={footerLinks.discounts.name}
            href={footerLinks.discounts.link}
          />
          <LinkItem
            icon={ReductionIcon}
            label={footerLinks.hometest.name}
            href={footerLinks.hometest.link}
          />
        </Box>
        {/* <Link to="/results">
          <Button className={classes.mobileBtn}></Button>
        </Link>
        <Link to="/discounts">
          <Button className={classes.mobileBtn}>
            <AmbulanceIcon />
            Скидки и акции
          </Button>
        </Link>
        <Link to="/home-visit">
          <Button className={classes.mobileBtn}>
            <ReductionIcon />
            Выезд на дом
          </Button>
        </Link> */}

        <IconButton className={classes.mobileMenuBtn} onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
      </BottomNavigation>

      <Box id="mobile-menu">
        <MobileMenu toggleDrawer={toggleDrawer} open={open} />
      </Box>
    </>
  );
};

export default MobileBottomNavigation;
