import React from 'react';

const AboutOrderHelperText = () => (
  <>
    <p className="helper-text">
      Стоимость анализов указана без учета дополнительных услуг по регистрации
      заказа и взятию биоматериала.
    </p>
    <p className="helper-text">
      Оплата заказа производится по ценам, действующим на день
      оплаты. Заказ, самостоятельно оформленный на сайте, не может быть оплачен
      частично или полностью страховой компанией. Заказ действителен в течение
      30 дней с момента создания. Заказы, включающие акционную услугу,
      действительны в течение срока действия акции
    </p>
  </>
);

export default AboutOrderHelperText;
