import React from 'react';
import './services-table.scss';
import '../../_helper/button.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import AddServiceButton from './AddServiceButton';
import ApiClient from '../../../Api/ApiClient';
import {
  GetPaginationInfo,
  AddSearchServices,
  SetLoading,
  AddServices,
  AddServicesFromServiceGroup,
} from '../../../__actions/service_actions';
import ServiceInfo from './Accordion';
import Spinner from '../../_helper/Spinner';
import { MEDICAL_GROUP } from '../../_helper/app-const';

class ServicesTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
    };
    this.handleClickPageChange = this.handleClickPageChange.bind(this);
  }

  componentDidMount() {
    const { SetLoadingAction } = this.props;

    SetLoadingAction(true);
    this.AddServices().finally(() => {
      SetLoadingAction();
    });
  }

  componentDidUpdate(prevProps) {
    const {
      gender, pageSize, serviceGroupId, term
    } = this.props;

    if (prevProps.serviceGroupId !== serviceGroupId) {
      this.GetServices(1, pageSize);
    } else {
      if (prevProps.term !== term) {
        if (term?.length > 0) {
          this.Search(term);
        } else if (term?.length === 0 && prevProps?.term > 0) {
          this.GetServices(1, pageSize);
        }
      }

      if (prevProps.gender !== gender) {
        if (term) {
          this.Search(term);
        } else {
          this.GetServices(1, pageSize);
        }
      }
    }
  }

  handleClickPageChange(event) {
    const { pageNumber } = this.props;
    const newPage = pageNumber + 1;
    this.setState({ disabled: true });
    this.AddServices(newPage).finally(() => {
      this.setState({ disabled: false });
    });
    if (event.target.className.indexOf('MuiButton-label') !== -1) {
      event.target.parentNode.blur();
    } else {
      event.target.blur();
    }
  }

  async GetServices(pageNumber, pageSize) {
    const {
      GetPaginationInfoAction,
      gender,
      serviceGroupId,
      serviceGroup,
      SetLoadingAction,
      SetServicesAction,
    } = this.props;

    SetLoadingAction(true);

    let clientServiceGroupId = null;
    let medicalServiceGroupId = null;

    if (serviceGroup === MEDICAL_GROUP) {
      medicalServiceGroupId = serviceGroupId;
    } else {
      clientServiceGroupId = serviceGroupId;
    }

    await ApiClient.Services(
      pageNumber,
      pageSize,
      gender,
      clientServiceGroupId,
      medicalServiceGroupId
    )
      .then((res) => {
        if (res) {
          const response = res.data;
          GetPaginationInfoAction(response.page);
          if (response.isSuccess) {
            SetServicesAction(response.services);
          } else {
            throw new Error(
              response.statusMessage || 'По вашему запросу анализов не найдено. Попробуйте изменить запрос или обратитесь за консультацией по телефону 7766'
            );
          }
        }
      })
      .catch((e) => {});

    SetLoadingAction(false);
  }

  async AddServices(pageNumber = 1) {
    const {
      GetPaginationInfoAction,
      pageSize,
      gender,
      serviceGroupId,
      serviceGroup,
      AddServicesAction,
    } = this.props;

    let clientServiceGroupId = null;
    let medicalServiceGroupId = null;

    if (serviceGroup === MEDICAL_GROUP) {
      medicalServiceGroupId = serviceGroupId;
    } else {
      clientServiceGroupId = serviceGroupId;
    }

    await ApiClient.Services(
      pageNumber,
      pageSize,
      gender,
      clientServiceGroupId,
      medicalServiceGroupId
    )
      .then((res) => {
        if (res) {
          const response = res.data;
          GetPaginationInfoAction(response.page);
          if (response.isSuccess) {
            AddServicesAction(response.services);
          } else {
            throw new Error(
              response.statusMessage || 'По вашему запросу анализов не найдено. Попробуйте изменить запрос или обратитесь за консультацией по телефону 7766'
            );
          }
        }
      })
      .catch((e) => {});
  }

  async Search(term) {
    const {
      AddSearchServicesAction,
      GetPaginationInfoAction,
      SetLoadingAction,
      gender,
      unenableSearch,
    } = this.props;
    try {
      SetLoadingAction(true);
      await ApiClient.Search(term, gender).then((res) => {
        if (res !== undefined && res.data.isSuccess) {
          // console.log(res);
          GetPaginationInfoAction({
            pageNumber: 1,
            hasNextPage: false,
            hasPreviousPage: false,
          });
          if (res.data.services.length) {
            unenableSearch();
            AddSearchServicesAction(res.data.services);
          } else {
            AddSearchServicesAction([], true);
          }
        }
      });
      SetLoadingAction(false);
    } catch (e) {
      AddSearchServicesAction([], true);
      SetLoadingAction(false);
    }
  }

  render() {
    const {
      services, hasNextPage, error, loading
    } = this.props;
    const { disabled } = this.state;
    const errorMessage = (
      <div className="px-2 my-3 my-sm-5">
        <h2 className="service-table__error">
            По вашему запросу анализов не найдено. Попробуйте изменить запрос или обратитесь за консультацией по телефону 7766
        </h2>
      </div>
    );

    const table = (
      <div className="table-wrap px-0 px-md-2">
        <table id="service-table">
          <thead>
            <tr>
              <td
                className="pl-3 pl-md-4 pr-1 pr-sm-0"
                style={{ width: '50%' }}
              >
                ПЕРЕЧЕНЬ АНАЛИЗОВ
              </td>
              <td className="pr-1 pr-sm-0 pl-0">
                ЦЕНА
                <br />
                (byn)
              </td>
              <td className="pr-1 pr-sm-0">
                <span className="d-sm-none">СРОК</span>
                <span className="d-none d-sm-block">
                  СРОК ВЫПОЛНЕНИЯ
                  <br />
                  (рабочих дней)
                </span>
              </td>
              <td
                className="pr-1 pr-sm-0 pl-1 pl-sm-0"
                style={{ width: '13%' }}
              >
                <span className="d-none d-sm-block">
                  ДОБАВИТЬ
                  <br />
                  В КОРЗИНУ
                </span>
                <span className="d-sm-none">В КОРЗИНУ</span>
              </td>
            </tr>
          </thead>
          <tbody className="service-table__body">
            {services.map((service, index) => (
              <React.Fragment key={service.code}>
                <tr>
                  <td aria-label="Перечень анализов" className="pl-3 pl-md-4">
                    {`${service.code} / ${service.name}`}
                  </td>
                  <td
                    className={
                      service.isNeedToAddOutsorsingIdentificator
                        ? 'service-table__outsource-service'
                        : undefined
                    }
                  >
                    {service.price}
                  </td>
                  <td aria-label="Срок выполнения (рабочих дней)">
                    {service.workingDays}
                  </td>
                  <td
                    aria-label="Добавить в корзину"
                    className="service-table__buttons"
                  >
                    {service.isOnlineOrder && (
                      <AddServiceButton service={service} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" className="pl-3 pl-md-5">
                    <ServiceInfo service={service} index={index} />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
          {hasNextPage && (
            <tfoot>
              <tr>
                <td className="service-table__footer" colSpan="5">
                  <div className="d-flex justify-content-center">
                    <Button
                      disabled={disabled}
                      className="btn-primary-filled btn-load-more"
                      onClick={this.handleClickPageChange}
                    >
                      Загрузить еще
                    </Button>
                  </div>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    );

    if (loading) {
      return <Spinner />;
    }

    if (error || services.length === 0) {
      return errorMessage;
    }
    return table;
  }
}

ServicesTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  term: PropTypes.string.isRequired,
  SetLoadingAction: PropTypes.func.isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  AddSearchServicesAction: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      comment: PropTypes.string,
      description: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      remark: PropTypes.string,
      workingDays: PropTypes.number,
    })
  ).isRequired,

  pageNumber: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  hasNextPage: PropTypes.bool,
  GetPaginationInfoAction: PropTypes.func.isRequired,
  gender: PropTypes.string.isRequired,
  serviceGroupId: PropTypes.number.isRequired,
  serviceGroup: PropTypes.string.isRequired,
  AddServicesAction: PropTypes.func.isRequired,
  SetServicesAction: PropTypes.func.isRequired,
  unenableSearch: PropTypes.func.isRequired,
};

ServicesTable.defaultProps = {
  pageNumber: 1,
  hasNextPage: true,
};

const mapStateToProps = (state) => ({
  loading: state.service.loading,
  error: state.service.error,
  services: state.service.services,
  serviceGroupId: state.service.medicalServiceGroupId,
  serviceGroup: state.service.serviceGroup,
  pageNumber: state.service.pageNumber,
  pageSize: state.service.pageSize,
  hasNextPage: state.service.hasNextPage,
  gender: state.user.gender,
  term: state.service.term,
});

const mapDispatchToProps = (dispatch) => ({
  AddSearchServicesAction: (services, error) => dispatch(AddSearchServices(services, error)),
  GetPaginationInfoAction: (page) => dispatch(GetPaginationInfo(page)),
  SetLoadingAction: (bool) => dispatch(SetLoading(bool)),
  AddServicesAction: (services) => dispatch(AddServices(services)),
  SetServicesAction: (services) => dispatch(AddServicesFromServiceGroup(services)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServicesTable);
