import { useDispatch, useSelector } from 'react-redux';
import { UnableRedirect } from '../../__actions/service_actions';

const useRedirect = () => {
  const isRedirect = useSelector((state) => state.service.redirect);
  const dispatch = useDispatch();

  const unenableRedirect = () => {
    if (isRedirect) {
      dispatch(UnableRedirect(false));
    }
  };

  return { isRedirect, unenableRedirect };
};

export default useRedirect;
