import ApiClient from '../../Api/ApiClient';
import errorHelper from './errorHelper';

class Service {
  /**
   * Получить услуги с ценами
   * @param {String} gender пол F или M
   * @param {String[]} codes список кодов родительских услуг
   * @returns {object[]} список услуг с доп. услугами
   */
  static Calculator = async (gender, codes) =>
    ApiClient.PriceWithDiscount(gender, codes).then((res) => {
      if (res?.data?.isSuccess && res.data?.priceBookService?.length > 0) {
        return res.data.priceBookService;
      }
      throw new RangeError('Ошибка, услуг нету');
    });

  static CheckServices = async (codes) =>
    ApiClient.CheckServices(codes)
      .then((res) => {
        if (res) {
          const { yes, no, isSuccess, statusMessage } = res.data;
          let conflict = false;

          if (isSuccess) {
            return { isSuccess: true, message: '', conflict: '' };
          }

          if (yes || no) {
            conflict = yes;
          }
          return { isSuccess: false, message: statusMessage, conflict };
        }
        throw new Error(errorHelper.sorryOccuredError);
      })
      .catch((e) => ({ isSuccess: false, message: e.message, conflict: '' }));
}

export default Service;
