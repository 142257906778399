import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import WebFont from 'webfontloader';
import md5 from 'js-md5';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './index.scss';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { store } from './App/__reducer/index';
import './App/Api/Intercepor';

React.Component.prototype.$md5 = md5;

WebFont.load({
  custom: {
    families: ['Lato', 'Golos'],
    urls: ['/fonts/fonts.css'],
  },
});

ReactDOM.render(
  <Provider store={store}>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_CAPTCHA_V3_KEY}
      scriptProps={{
        async: true, // optional, default to false,
        defer: true, // optional, default to false
        // appendTo: 'head', // optional, default to "head", can be "head" or "body",
        // nonce: undefined, // optional, default undefined
      }}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
