import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import { throttle } from 'lodash-es';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import basketReducer from './BasketReducer';
import userReducer from './UserReducer';
import serviceReducer from './ServiceReducer';
import adminReducer from './AdminReducer';
import recaptchaReducer from './recaptchaReducer';
import LoadingReducer from './LoadingReducer';
import ServiceConflictReducer from './ServiceConflictReducer';
import discountReducer from './discountReducer';
import { getStorage, setStorage } from '../LocalStorage/localStorage';
import addingServiceReducer from './addingServiceReducer';

const ResetReducers = createAction('Reset');

const appReducer = combineReducers({
  basket: basketReducer,
  user: userReducer,
  service: serviceReducer,
  admin: adminReducer,
  recaptchaReducer,
  loading: LoadingReducer,
  conflict: ServiceConflictReducer,
  discount: discountReducer,
  addingService: addingServiceReducer,
});

const rootReducer = (state, action) => {
  if (action.type === ResetReducers.type) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};

let middleWare;
if (process.env.NODE_ENV === 'production') {
  middleWare = applyMiddleware(thunk);
} else {
  middleWare = composeWithDevTools(applyMiddleware(logger, thunk));
}

const state = getStorage('state');
const create = state === null
  ? createStore(rootReducer, middleWare)
  : createStore(rootReducer, state, middleWare);

const store = create;

store.subscribe(
  throttle(() => {
    setStorage('state', {
      basket: store.getState().basket,
      user: store.getState().user,
      admin: store.getState().admin,
      discount: store.getState().discount,
    });
  }, 1000)
);

export { ResetReducers, store };
