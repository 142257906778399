export const GET_PAGINATION_INFO = 'GET_PAGINATION_INFO';
export const MEDICAL_SERV_GROUP_BREADCRUMBS = 'MEDICAL_SERV_GROUP_BREADCRUMBS';
export const ADD_SORT_MEDICAL_SERVICE_GROUP = 'ADD_SORT_MEDICAL_SERVICE_GROUP';
export const IS_OPEN_SIDEBAR = 'IS_OPEN_SIDEBAR';
export const ADD_SERVICES = 'ADD_SERVICES';
export const ADD_SERVICES_FROM_SERVICE_GROUP =
  'ADD_SERVICES_FROM_SERVICE_GROUP';
export const ADD_SEARCH_SERVICES = 'ADD_SEARCH_SERVICES';
export const ADD_MEDICAL_SERVICE_GROUP_ID = 'ADD_MEDICAL_SERVICE_GROUP_ID';
export const ADD_SERVICE_GROUP = 'ADD_SERVICE_GROUP';
export const REDIRECT = 'REDIRECT';
export const LOADING = 'SET_LOADING';

export const serviceConst = {
  SEARCH_LOADING: 'SERVICE_SEARCH_LOADING',
  SEARCH_SUCCESS: 'SERVICE_SEARCH_SUCCESS',
  SEARCH_FAIL: 'SERVICE_SEARCH_FAIL',
  SERVICE_GROUP_ERROR: 'SERVICE_GROUP_ERROR',
  SEARCH_TERM: 'SET_SEARCH',
};
