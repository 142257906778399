import React from 'react';
import {
  Badge,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReactComponent as BasketIcon } from './img/basket.svg';
import { changeIsOpenBasket } from '../../__reducer/BasketReducer';
import HeaderButton from './HeaderButton';

const styles = makeStyles({
  badge: {
    height: 24,
    minWidth: 24,
    borderRadius: 14,

    backgroundColor: '#00BE6E',
    color: 'white',

    fontFamily: 'Lato',
    fontSize: '14px',
  },
});

const BasketButton = React.memo(
  ({ changeIsOpenBasketAction, parrentCodesLength }) => {
    const handleClick = () => {
      changeIsOpenBasketAction();
    };
    const classes = styles();

    return (
      <HeaderButton
        onClick={handleClick}
        aria-label="Корзина"
        className="order-xl-2 basket-button"
      >
        <Badge
          badgeContent={parrentCodesLength}
          classes={{
            badge: classes.badge,
          }}
        >
          <BasketIcon />
        </Badge>
      </HeaderButton>
    );
  }
);

const mapDispatchToProps = (dispatch) => ({
  changeIsOpenBasketAction: () => dispatch(changeIsOpenBasket()),
});

const mapStateToProps = (state) => ({
  parrentCodesLength: state.basket.parrentServiceCodes.length,
});

BasketButton.propTypes = {
  parrentCodesLength: PropTypes.number.isRequired,
  changeIsOpenBasketAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketButton);
