import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Table, TableHead, TableRow, TableCell
} from '@material-ui/core';
import { servicesPage } from '../../../routing/PageRoutes';
import './basket-full.scss';
import ClickPopover from '../ClickPopover/ClickPopover';
import RemoveServiceButton from './RemoveServiceButton';
import AboutOrderHelperText from '../../services_page/AboutOrderHelperText';
import StyledButton from '../StyledTextButton';
import ClearBasketButton from './ClearBasketButton';
import CheckoutOrderButton from '../CheckoutOrderButton';

function isParrentService(arr, elem) {
  return arr.indexOf(elem) !== -1;
}

const BasketFull = (props) => {
  const {
    services,
    totalPrice,
    onCancel,
    parrentServiceCodes,
    totalPriceWithDiscount,
  } = props;

  return (
    <>
      <div className="basket-full mb-2">
        <div className="basket-full__table-wrap syn-scroll">
          <Table stickyHeader className="table mb-3">
            <TableHead className="basket-full__header">
              <TableRow>
                <TableCell scope="col" className="p-0 pl-3 pl-md-4">
                  ПЕРЕЧЕНЬ АНАЛИЗОВ
                </TableCell>
                <TableCell scope="col" aria-label="Дополнительная информация" />
                <TableCell scope="col" className="px-1">
                  ЦЕНА (byn)
                </TableCell>
                <TableCell scope="col" colSpan="2" className="pl-1 pr-2">
                  СРОК&nbsp;
                  <span className="d-block d-md-none" />
                  ВЫПОЛНЕНИЯ (рабочих дней)
                </TableCell>
              </TableRow>
            </TableHead>
            <tbody>
              {services.map((item) => (
                <tr className="basket-full__row" key={item.name}>
                  <td className="pl-3 pl-md-4">{`${item.code} / ${item.name}`}</td>
                  <td>
                    {isParrentService(parrentServiceCodes, item.code)
                      && (item.remark
                        || item.comment
                        || item.description
                        || item.results) && <ClickPopover service={item} />}
                  </td>
                  <td className="px-1">{item.price}</td>
                  <td className="px-1">
                    {item.workingDays > 0 && item.workingDays}
                  </td>
                  <td className="px-0">
                    {isParrentService(parrentServiceCodes, item.code) && (
                      <RemoveServiceButton code={item.code} />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <ClearBasketButton />
        <div className="d-flex flex-column text-right mb-4 mb-md-5 px-4">
          <div className="basket-full__price basket-full__price-sum color-secondary">
            {`Итого ${totalPriceWithDiscount}`}
          </div>
        </div>
        <div className="row no-gutters px-3 px-sm-4">
          <div className="col-12 mb-3 col-sm-6 mb-sm-0 pr-sm-3">
            <StyledButton
              fullWidth
              height="medium"
              component={Link}
              onClick={onCancel}
              to={servicesPage}
              variant="outlined"
            >
              Добавить анализы
            </StyledButton>
          </div>
          <div className="col-12 mb-3 col-sm-6 mb-sm-0 pl-sm-3">
            <CheckoutOrderButton closeBasket={onCancel} />
          </div>
        </div>
      </div>
      <div className="basket__footer">
        <AboutOrderHelperText />
      </div>
    </>
  );
};

BasketFull.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      comment: PropTypes.string,
      description: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      remark: PropTypes.string,
      workingDays: PropTypes.number,
    })
  ).isRequired,
  totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onCancel: PropTypes.func.isRequired,
  parrentServiceCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalPriceWithDiscount: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  services: state.basket.addedServices,
  totalPrice: state.basket.total,
  parrentServiceCodes: state.basket.parrentServiceCodes,
  totalPriceWithDiscount: state.basket.totalWithDiscount
    ? Number.parseFloat(state.basket.totalWithDiscount)
    : 0,
});

export default connect(mapStateToProps)(BasketFull);
