import { CircularProgress, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useService from '../../_helper/scripts/useService';
import { ReactComponent as DeleteIcon } from '../img/delete.svg';
import { fetchStatus } from '../../_helper/fetchStatus';

const RemoveServiceButton = ({ code }) => {
  const status = useSelector((state) => state.basket.status);
  const loading = status === fetchStatus.PENDING;
  const { RemoveService } = useService();
  const [clicked, setClicked] = useState(false);

  const handleRemoveService = () => {
    setClicked(true);
    RemoveService(code);
  };

  return (
    <IconButton
      onClick={handleRemoveService}
      aria-label="Удалить услугу"
      className="m-1"
      size="small"
      disabled={loading}
    >
      {loading && clicked ? (
        <CircularProgress
          size={20}
          color="primary"
        />
      ) : (
        <DeleteIcon />
      )}
    </IconButton>
  );
};

RemoveServiceButton.propTypes = {
  code: PropTypes.string.isRequired
};

export default React.memo(RemoveServiceButton);
