const SET_LOADING = 'SERVICE-BACKDROP-LOADING';

export function setServiceLoading(payload) {
  return {
    type: SET_LOADING,
    payload,
  };
}

const initState = {
  value: false,
};

const LoadingReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        value: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default LoadingReducer;
