import {
  GET_PAGINATION_INFO,
  MEDICAL_SERV_GROUP_BREADCRUMBS,
  ADD_SORT_MEDICAL_SERVICE_GROUP,
  IS_OPEN_SIDEBAR,
  ADD_SERVICES,
  ADD_SERVICES_FROM_SERVICE_GROUP,
  ADD_SEARCH_SERVICES,
  ADD_MEDICAL_SERVICE_GROUP_ID,
  ADD_SERVICE_GROUP,
  REDIRECT,
  LOADING,
  serviceConst,
} from '../__actions/action_const/service_const';

const initState = {
  pageNumber: 1,
  pageSize: 20,
  hasNextPage: true,
  hasPreviousPage: false,
  totalPages: 1,
  medicalServiceGroupBreadcrumbs: { title: '', subTitle: '' },
  medicalServiceGroup: [],
  serviceGroupError: '',
  sortMedicalServiceGroup: [],
  services: [],
  error: false,
  isVisible: false,
  medicalServiceGroupId: null,
  serviceGroup: null,
  redirect: false,
  loading: false,
  term: '',
};

export default function serviceReducer(state = initState, action) {
  switch (action.type) {
    case GET_PAGINATION_INFO: {
      const page = action.payload;
      return {
        ...state,
        pageNumber: page.pageNumber,
        hasNextPage: page.hasNextPage,
        hasPreviousPage: page.hasPreviousPage,
        totalPages: page.totalPages,
      };
    }
    case serviceConst.SEARCH_TERM: {
      return {
        ...state,
        term: action.payload,
      };
    }
    case MEDICAL_SERV_GROUP_BREADCRUMBS: {
      const { title, subTitle } = action.payload;
      if (subTitle) {
        return {
          ...state,
          medicalServiceGroupBreadcrumbs: {
            title: title.name,
            subTitle: subTitle.name,
          },
        };
      }
      return {
        ...state,
        medicalServiceGroupBreadcrumbs: { title: title.name },
      };
    }
    case ADD_SORT_MEDICAL_SERVICE_GROUP: {
      return {
        ...state,
        sortMedicalServiceGroup: action.payload,
      };
    }
    case IS_OPEN_SIDEBAR: {
      return {
        ...state,
        isVisible: !state.isVisible,
      };
    }

    case ADD_SERVICES: {
      return {
        ...state,
        services: state.services.concat(action.payload),
      };
    }
    case ADD_SERVICES_FROM_SERVICE_GROUP: {
      return {
        ...state,
        services: action.payload,
        error: action.error,
      };
    }
    case ADD_SEARCH_SERVICES: {
      return {
        ...state,
        services: action.payload,
        error: action.error,
      };
    }
    case ADD_MEDICAL_SERVICE_GROUP_ID: {
      return {
        ...state,
        medicalServiceGroupId: action.payload,
        term: '',
      };
    }
    case ADD_SERVICE_GROUP: {
      return {
        ...state,
        serviceGroup: action.payload,
      };
    }
    case REDIRECT: {
      return {
        ...state,
        redirect: action.payload,
        medicalServiceGroupBreadcrumbs: { title: '', subTitle: '' },
        error: action.error,
      };
    }
    case LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case serviceConst.SEARCH_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case serviceConst.SEARCH_SUCCESS: {
      return {
        ...state,
        services: action.payload.services,
        hasNextPage: false,
        error: false,
        loading: false,
      };
    }
    case serviceConst.SEARCH_FAIL: {
      return {
        ...state,
        services: [],
        error: true,
        loading: false,
      };
    }
    case serviceConst.SERVICE_GROUP_ERROR: {
      return {
        ...state,
        serviceGroupError: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
