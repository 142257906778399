import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Link,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { ReactComponent as Arrow } from '../img/newArrow.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Golos',
    padding: '12px 16px',
    color: '#313335',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  dropdownItem: {
    fontFamily: 'Golos',
    color: '#616365',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
  },
}));

function AccordionHeader({ MenuItem }) {
  const classes = useStyles();

  if (MenuItem.links) {
    return (
      <Accordion square elevation={1}>
        <AccordionSummary expandIcon={<Arrow />}>
          {MenuItem.link ? (
            <Link href={MenuItem.link}>
              <Typography className={classes.title}>{MenuItem.title}</Typography>
            </Link>
          ) : (
            <Typography className={classes.title}>{MenuItem.title}</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {MenuItem.links
              .map((item) => (
                <ListItem key={item.linkName} component={Link} href={item.link}>
                  <Typography className={classes.dropdownItem}>{item.linkName}</Typography>
                </ListItem>
              ))}
          </List>
        </AccordionDetails>
      </Accordion>
    );
  }

  if (MenuItem.link) {
    return (
      <Accordion square elevation={1}>
        <ListItem component={Link} href={MenuItem.link} fullWidth>
          <Typography className={classes.title}>{MenuItem.title}</Typography>
        </ListItem>
      </Accordion>
    );
  }
}

AccordionHeader.propTypes = {
  MenuItem: PropTypes.shape({
    link: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    title: PropTypes.string,
  }).isRequired,
};

export default AccordionHeader;
